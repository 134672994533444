/* eslint-disable max-len */
import React, { FC } from 'react'
import { Badge, Progress, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import useMeasureY from '../../../../../hooks/use-measurey'
import { blue } from '@ant-design/colors'
import { IOrderDispatchedGroupResponse } from '../../../../../services/order-dispatched/order-dispatched.interface'
import OrderDeliveryGroup2 from './parts/OrderDeliveryGroup2'


interface IOrdersGroupTableProps {
  data: IOrderDispatchedGroupResponse[] | undefined
  loading: boolean
}

const OrderDeliveryGroup1: FC<IOrdersGroupTableProps> = ({ data, loading }) => {
  const { windowSize } = useMeasureY()

  const columnsLevel1: ColumnsType<IOrderDispatchedGroupResponse> = [
    {
      title: 'Guía despacho', dataIndex: 'guideNumberAssigned', width: 200,
    },
    {
      title: 'Pedidos', dataIndex: 'totalOrder', width: 100, align: 'right',
      render: (_, order) => <>
        <Badge count={order.totalOrder} style={{ backgroundColor: '#888' }} />
      </>,
      sorter: (a, b) => a.totalOrder - b.totalOrder,
    },
    {
      title: 'Total', dataIndex: 'totalAmount', align: 'left',
      render: (_, order) => <>
        {order.currency?.symbol} {order.totalAmount}
        <Progress
          style={{ marginLeft: '.5rem', width: '50px' }}
          percent={order.ratioAmount}
          size="small"
          showInfo={false}
          strokeColor={blue[5]}
        />
      </>,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
  ]

  return (
    <>
      <Table
        rowKey="guideNumberAssigned"
        columns={columnsLevel1}
        dataSource={data}
        pagination={false}
        scroll={{
          y: windowSize.height - 200,
        }}
        size="small"
        loading={loading}
        expandable={{
          expandedRowRender: OrderDeliveryGroup2,
          rowExpandable: record => !!record.orders?.length,
        }}
      />
    </>
  )
}

export default OrderDeliveryGroup1
