import React from 'react';
import {
  BarChartOutlined,
  CarOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  FileTextOutlined,
  FolderOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { ReactNode } from 'react';
import { SignInView } from '../pages/SignIn';
import { FrequentQuestionView } from '../pages/FrequentQuestionModule';
import LandingView from '../pages/Landing/LadingView';
import { Navigate } from 'react-router-dom';
import { DashboardView } from '../pages/Dashboard';
import { HomeView } from '../pages/Home';
import { AliNewsView } from '../pages/AliNews';
import {
  DeliveriesView,
  MyPreOrdersView,
  OrdersAgencyView,
  OrderShopifyView,
  OrdersView,
} from '../pages/OrderModule';
import {
  FaqMaintenanceView,
  MovementsMaintView,
  ProductsMaintView,
  StoresMaintView,
  UsersMaintView,
  UsersTransporterView,
  WarehousesMainView,
} from '../pages/MaintenanceModule';
import TransportersMaintView from '../pages/MaintenanceModule/TransportersMaintView/TransportersMaintView';
import { CoveragesView, RoutesView } from '../pages/RoutesModule';
import {
  DispatchGuideMaintView,
  OrderAssignmentView,
  OrdersToReturnView,
  ReturnMaintView,
} from '../pages/DispatchModule';
import { ValidationDepositView } from '../pages/LiquidationModule';
import { GeneralCatalogView, ManageCatalogView } from '../pages/Catalog';
import { SettingsView } from '../pages/Settings';
import { TicketSupportView } from '../pages/Support';
import { ReportView } from '../pages/Report';
import TaskView from '../pages/Task/TaskView';
import { Tag } from 'antd';

export interface IRoutePath {
  PATH: string;
  ELEMENT: ReactNode;
  TITLE?: string;
  PUBLIC?: boolean;
  ICON?: ReactNode;
  TAG?: string | ReactNode;
  CHILDREN?: IRoutePath[];
}

export const ROUTES: { [key: string]: IRoutePath } = {
  /**
   * Public Routes
   */
  HOME: {
    PATH: '/home',
    TITLE: 'Landig',
    ELEMENT: <LandingView />,
    PUBLIC: true,
  },
  SIGN_IN: {
    PATH: '/login',
    TITLE: 'Iniciar Sesión',
    PUBLIC: true,
    ELEMENT: <SignInView />,
  },
  SIGN_IN_CODE: {
    PATH: ':code',
    TITLE: 'Iniciar Sesión',
    PUBLIC: true,
    ELEMENT: <SignInView />,
  },
  // FAQ Section
  FREQUENT_QUESTION_PUBLIC: {
    PATH: '/preguntas-frecuentes',
    TITLE: 'Preguntas frecuentes',
    ELEMENT: <FrequentQuestionView />,
    PUBLIC: true,
  },
  FREQUENT_QUESTION: {
    PATH: '/faq',
    TITLE: '¿Cómo usar Aliclik?',
    ELEMENT: <FrequentQuestionView />,
    ICON: <QuestionCircleOutlined />,
    TAG: (
      <Tag color="#2196F3" style={{ marginLeft: 10 }}>
        NUEVO
      </Tag>
    ),
  },

  /**
   * Private Routes
   */
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    ELEMENT: <DashboardView />,
    ICON: <HomeOutlined />,
  },
  METRICS: {
    PATH: '/metrics',
    TITLE: 'Mis métricas',
    ELEMENT: <HomeView />,
    ICON: <BarChartOutlined />,
  },
  ALINEWS: {
    PATH: '/alinews',
    TITLE: 'AliNews',
    ELEMENT: <AliNewsView />,
    ICON: <HomeOutlined />,
  },
  ORDER_REDIRECT: {
    PATH: '/order',
    ELEMENT: <Navigate to="pre-orders" replace />,
  },
  ORDER: {
    PATH: '/order',
    TITLE: 'Pedidos',
    ELEMENT: undefined,
    ICON: <ShoppingCartOutlined />,
    CHILDREN: [
      {
        PATH: 'pre-orders',
        TITLE: 'PreOrdenes',
        ELEMENT: <MyPreOrdersView />,
      },
      {
        PATH: 'orders',
        TITLE: 'Pedidos Contraentrega',
        ELEMENT: <OrdersView />,
      },
      {
        PATH: 'deliveries',
        TITLE: 'Seguimiento Contraentrega',
        ELEMENT: <DeliveriesView />,
      },
      {
        PATH: 'orders-agency',
        TITLE: 'Envíos Agencia',
        ELEMENT: <OrdersAgencyView />,
      },
      {
        PATH: 'orders-shopify',
        TITLE: 'Shopify',
        ELEMENT: <OrderShopifyView />,
      },
    ],
  },
  MAINTENANCE_REDIRECT: {
    PATH: '/maintenance',
    ELEMENT: <Navigate to="stores" replace />,
  },
  MAINTENANCE: {
    PATH: '/maintenance',
    TITLE: 'Mantenimiento',
    ELEMENT: undefined,
    ICON: <InsertRowAboveOutlined />,
    CHILDREN: [
      {
        PATH: 'users',
        TITLE: 'Usuarios Tienda',
        ELEMENT: <UsersMaintView />,
      },
      {
        PATH: 'users-conveyor',
        TITLE: 'Usuarios Transportadora',
        ELEMENT: <UsersTransporterView />,
      },
      {
        PATH: 'stores',
        TITLE: 'Tiendas',
        ELEMENT: <StoresMaintView />,
      },
      {
        PATH: 'products',
        TITLE: 'Productos',
        ELEMENT: <ProductsMaintView />,
      },
      {
        PATH: 'warehouses',
        TITLE: 'Almacenes',
        ELEMENT: <WarehousesMainView />,
      },
      {
        PATH: 'movements',
        TITLE: 'Movimientos',
        ELEMENT: <MovementsMaintView />,
      },
      {
        PATH: 'transporters',
        TITLE: 'Transportadoras',
        ELEMENT: <TransportersMaintView />,
      },
      {
        PATH: 'faqs',
        TITLE: 'Preguntas Frecuentes',
        ELEMENT: <FaqMaintenanceView />,
      },
    ],
  },
  ROUTESMANAGEMENT_REDIRECT: {
    PATH: '/routes-management',
    ELEMENT: <Navigate to="coverages" replace />,
  },
  ROUTESMANAGEMENT: {
    PATH: '/routes-management',
    TITLE: 'Gestión de rutas',
    ELEMENT: undefined,
    ICON: <CarOutlined />,
    CHILDREN: [
      {
        PATH: 'coverages',
        TITLE: 'Coberturas',
        ELEMENT: <CoveragesView />,
      },
      { PATH: 'routes', TITLE: 'Rutas', ELEMENT: <RoutesView /> },
    ],
  },
  DISPATCHES_REDIRECT: {
    PATH: '/dispatches',
    ELEMENT: <Navigate to="dispatch-management" replace />,
  },
  DISPATCHES: {
    PATH: '/dispatches',
    TITLE: 'Traslados',
    ELEMENT: undefined,
    ICON: <CarOutlined />,
    CHILDREN: [
      {
        PATH: 'dispatch-assignment',
        TITLE: 'Asignación',
        ELEMENT: <OrderAssignmentView />,
      },
      {
        PATH: 'dispatch-management',
        TITLE: 'Gestión Guías',
        ELEMENT: <DispatchGuideMaintView />,
      },
      {
        PATH: 'return-management',
        TITLE: 'Devoluciones',
        ELEMENT: <ReturnMaintView />,
      },
      {
        PATH: 'orders-to-return',
        TITLE: 'Pedidos no entregados',
        ELEMENT: <OrdersToReturnView />,
      },
    ],
  },
  LIQUIDATIONS_REDIRECT: {
    PATH: '/liquidations',
    ELEMENT: <Navigate to="deposits" replace />,
  },
  LIQUIDATIONS: {
    PATH: '/liquidations',
    TITLE: 'Liquidación',
    ELEMENT: undefined,
    ICON: <DollarOutlined />,
    CHILDREN: [
      {
        PATH: 'deposits',
        TITLE: 'Depósitos',
        ELEMENT: <ValidationDepositView />,
      },
    ],
  },
  CATALOG: {
    PATH: '/catalog',
    TITLE: 'Catálogo',
    ELEMENT: undefined,
    ICON: <FolderOutlined />,
    CHILDREN: [
      {
        PATH: 'general',
        TITLE: 'Catálogo General',
        ELEMENT: <GeneralCatalogView />,
      },
      {
        PATH: 'manage',
        TITLE: 'Adminitrar Catálogos',
        ELEMENT: <ManageCatalogView />,
      },
    ],
  },
  SETTING: {
    PATH: '/setting',
    TITLE: 'Configuración',
    ELEMENT: <SettingsView />,
    ICON: <ToolOutlined />,
  },
  SUPPORT: {
    PATH: '/ticket',
    TITLE: 'Atención al Cliente',
    ELEMENT: <TicketSupportView />,
    ICON: <CustomerServiceOutlined />,
    TAG: (
      <Tag color="#2196F3" style={{ marginLeft: 10 }}>
        NUEVO
      </Tag>
    ),
  },
  REPORTS: {
    PATH: '/reports',
    TITLE: 'Reportes',
    ELEMENT: <ReportView />,
    ICON: <FileTextOutlined />,
  },
  TASK: {
    PATH: '/task',
    TITLE: 'Logs',
    ELEMENT: <TaskView />,
    ICON: <FileTextOutlined />,
  },
};
