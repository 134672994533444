import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, InputNumber, Radio, Select } from 'antd'
import { 
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../services/location/location.service'
import { ICoverageCreateSchema } from '../../../../../services/coverage-create/coverage-create.schema'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useCoverageStore } from '../../../../../services/coverage-create/coverage-create.store'

interface ICoverageEditFormProps {
  onSubmit: (data: ICoverageCreateSchema) => void;
}

const CoverageEditForm: FC<ICoverageEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICoverageCreateSchema>()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } = useLocationLevel2()
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } = useLocationLevel3()
  const { getWarehouses, warehouses, loadingWarehouses } = useWarehouseByCountry()
  const departmentId = Form.useWatch(['department'], form)
  const provinceId = Form.useWatch(['province'], form)
  const districtIds = Form.useWatch(['districtIds'], form)
  const warehouseIds = Form.useWatch(['warehouseIds'], form)
  const { coverageUbigeoUpdated } = useCoverageStore()
  const [ disabled, setDisabled] = useState<boolean>(false)

  const handleSubmit = (data: ICoverageCreateSchema) => {
    
    onSubmit(data)
  }

  useEffect(() => {
    if (departmentId && locationsGeolevel1.length > 0) {

      const ubi1 = locationsGeolevel1.find(w => `${w.id}` === `${departmentId}`)

      if (ubi1) {
        form.setFieldValue('dpto', ubi1)
      }

      getLocationsGeoLevel2({
        parentId: departmentId
      })

    }
  }, [departmentId])

  useEffect(() => {
    if (provinceId && locationsGeolevel2.length > 0) {

      const ubi2 = locationsGeolevel2.find(w => `${w.id}` === `${provinceId}`)

      if (ubi2) {
        form.setFieldValue('prov', ubi2)
      }

      getLocationsGeoLevel3({
        parentId: provinceId
      })

    }
  }, [provinceId])

  useEffect(() => {
    if (districtIds && districtIds.length > 0 && locationsGeolevel3.length > 0) {

      const ids: any[] = []

      districtIds.forEach(d => {

        const dist = locationsGeolevel3.find(dl => `${d}` === `${dl.id}`)

        if (dist) {
          ids.push({ id: dist.id, name: dist.name })
        }

      })

      form.setFieldValue('districts', ids)
      
    }
  }, [districtIds])

  useEffect(() => {
    getLocationsGeoLevel1()
    getWarehouses()

    if (coverageUbigeoUpdated) {

      setDisabled(true)

      form.setFieldsValue({
        id: `${coverageUbigeoUpdated.id}`,
        warehouseIds: [coverageUbigeoUpdated.warehouseId],
        flagDeadLineTime: coverageUbigeoUpdated.flagDeadLineTime === true ? 1 : 0,
        deliveryCost: coverageUbigeoUpdated.deliveryCost,
        deliveryCostPremium: coverageUbigeoUpdated.deliveryCostPremium,
        returnCost: coverageUbigeoUpdated.returnCost,
        returnCostPremium: coverageUbigeoUpdated.returnCostPremium,
        motorizedCost: coverageUbigeoUpdated.motorizedCost,
        addDays: coverageUbigeoUpdated.addDays,
      })

      if (coverageUbigeoUpdated.ubigeo.nivel === 1) {
        form.setFieldValue('department', coverageUbigeoUpdated.ubigeoId)
      } else if (coverageUbigeoUpdated.ubigeo.nivel === 2) {

        getLocationsGeoLevel2({
          parentId: coverageUbigeoUpdated.ubigeo.parentId
        })

        form.setFieldValue('province', coverageUbigeoUpdated.ubigeoId)
        form.setFieldValue('department', coverageUbigeoUpdated.ubigeo.parentId)

      } else if (coverageUbigeoUpdated.ubigeo.nivel === 3) {

        getLocationsGeoLevel3({
          parentId: coverageUbigeoUpdated.ubigeo.parentId
        })

        form.setFieldValue('districtIds', [coverageUbigeoUpdated.ubigeoId])
        form.setFieldValue('province', coverageUbigeoUpdated.ubigeo.parentId)

        if (coverageUbigeoUpdated.ubigeos && coverageUbigeoUpdated.ubigeos.dptoId) {

          getLocationsGeoLevel2({
            parentId: parseInt(`${coverageUbigeoUpdated.ubigeos.dptoId}`)
          })

          form.setFieldValue('department', coverageUbigeoUpdated.ubigeos.dptoId)
        }

      }

    } else {

      setDisabled(false)

      form.setFieldValue('flagDeadLineTime', 0)
    }
  }, [])

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      form={form}
      name="order"
      labelWrap
      onFinish={handleSubmit}
    >

      <Form.Item
        label="Departamento"
        name={['department']}
        rules={[{ required: true, message: '' }]}
      >
        <Select
          showSearch
          loading={loadingGeo1}
          disabled={disabled}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={locationsGeolevel1?.filter(f => f.flagContraentrega).map(e => ({
            label: e.name,
            value: e.id,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Provincia"
        name={['province']}
        rules={[{ required: false, message: '' }]}
      >
        <Select
          showSearch
          loading={loadingGeo2}
          disabled={disabled}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={locationsGeolevel2?.filter(f => f.flagContraentrega).map(e => ({
            label: e.name,
            value: e.id,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Distrito(s)"
        name={['districtIds']}
        rules={[{ required: false, message: '' }]}
      >
        <Select
          allowClear
          mode="multiple"
          showSearch
          loading={loadingGeo3}
          disabled={disabled}
          placeholder="Seleccione"
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'SELECCIONAR TODOS', value: 'ALL' },
            ...(locationsGeolevel3?.filter(f => f.flagContraentrega).map((e) => ({
              label: e.name,
              value: e.id
            })) || []),
          ]}
          onChange={(value) => {
            if (value.includes('ALL')) {
              form.setFieldsValue({
                districtIds: locationsGeolevel3?.filter(f => f.flagContraentrega).map((ubi) => ubi.id) || [],
              });
            } 

          }}
        />
      </Form.Item>

      <Form.Item
        label="Almacen(es)"
        name={['warehouseIds']}
        rules={[{ required: false, message: '' }]}
      >
        <Select
          allowClear
          mode="multiple"
          showSearch
          disabled={disabled}
          loading={loadingWarehouses}
          placeholder="Seleccione"
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          options={[
            { label: 'SELECCIONAR TODOS', value: 'ALL' },
            ...(warehouses?.map((e) => ({
              label: e.name,
              value: e.id
            })) || []),
          ]}
          onChange={(value) => {
            if (value.includes('ALL')) {
              form.setFieldsValue({
                warehouseIds: warehouses?.map((war) => war.id) || [],
              });
            } 

          }}
        />
      </Form.Item>

      <Form.Item
        label='Dias disponible'
        name={['addDays']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de entrega'
        name={['deliveryCost']}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de devolución'
        name={['returnCost']}
        rules={[{ required: true, message: '' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de entrega premium'
        name={['deliveryCostPremium']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de devolución premium'
        name={['returnCostPremium']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label='Costo de motorizado'
        name={['motorizedCost']}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label=''
        name={['id']}
        hidden
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label=''
        name={['dpto']}
        hidden
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label=''
        name={['prov']}
        hidden
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label=''
        name={['districts']}
        hidden
      >
        <InputNumber />
      </Form.Item>

      { warehouseIds && warehouseIds.length > 0 ? <>

        <Form.Item
          label="Hora de cierre"
          name={['flagDeadLineTime']}
          rules={[{ required: true, message: '' }]}>
          
          <Radio.Group>
            <Radio value={1}>Principal</Radio>
            <Radio value={0}>Secundario</Radio>
          </Radio.Group>
        </Form.Item>

      </> : <></>}

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CoverageEditForm
