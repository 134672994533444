import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Drawer, Layout, theme } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { MenuOutlined } from '@ant-design/icons';
import './LayoutApp.css';
import { useAuthStore } from '../../services/auth/auth.service';
import { Navbar, SidebarMenu, UserProfile } from './components';

export const LayoutApp = () => {
  const [open, setOpen] = useState(false);
  const { authUser } = useAuthStore();
  const { token } = theme.useToken();

  return (
    <Layout className="LayoutApp">
      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setOpen(false)}
        open={open}
        destroyOnClose
        width={280}
        styles={{
          body: {
            padding: 0,
            marginTop: 12,
          },
        }}
      >
        <UserProfile user={authUser.user} />
        <SidebarMenu onSelect={() => setOpen(false)} />
      </Drawer>

      <Layout
        style={{
          width: '100%',
          background: token.colorBgBase,
        }}
      >
        <Navbar>
          {React.createElement(MenuOutlined, {
            className: 'LayoutApp__collapsible',
            onClick: () => setOpen(!open),
          })}
        </Navbar>

        <Content className="LayoutApp__content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
