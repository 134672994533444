import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Select,
  Spin,
  Typography,
} from 'antd';
import { downloadExcel } from 'react-export-table-to-excel';
import { useAuthStore } from '../../../../services/auth/auth.service';
import {
  useCompanyDrop,
  useStockReport,
} from '../../../../services/order-report-created/order-report-created.service';
import { useTransporterList } from '../../../../services/transporter-list/transporter-list.service';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const StockReport = () => {
  const { authUser } = useAuthStore();
  const dateFormat = 'YYYY-MM-DD';
  const { getStockReport, responseSkReports, loadingStockReports } =
    useStockReport();
  const [ecomSelected, setEcomSelected] = useState<string>('');
  const { getCompaniesDrop, loadingCompanies, companies } = useCompanyDrop();
  const [hiddenEcom, setHiddenEcom] = useState(false);
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const { transporters, loadingTransporters, getTransporters } =
    useTransporterList();
  const [transpSelected, setTranspSelected] = useState<string>('ALL');
  const [hiddenTransp, setHiddenTransp] = useState(false);

  const headerOrder = [
    'SKU_ID',
    'EAN',
    'PRODUCTO',
    'VARIANTE',
    'PRECIO DROP',
    'TOTAL',
    'CANTIDAD',
    'NRO. PEDIDO',
    'ALMACÉN',
    'TRANSPORTADORA',
    'FECHA ENTREGA',
    'ESTADO ENTREGA',
    'ESTADO DESPACHO',
    'DROPSHALOM',
    'TIPO DE VENTA',
    'CORREO DROPSHIPPER',
    'DOCUMENTO DROPSHIPPER',
    'NOMBRE DROPSHIPPER',
  ];

  function handleDownloadExcelOrders() {
    getStockReport(
      selectedDatesOrder[0].format('YYYY-MM-DD'),
      selectedDatesOrder[1].format('YYYY-MM-DD'),
      parseInt(ecomSelected),
      authUser.user.company.countryCode,
      transpSelected,
    );
  }

  useEffect(() => {
    if (responseSkReports && responseSkReports.length > 0) {
      downloadExcel({
        fileName:
          `stock-report-${selectedDatesOrder[0].format('YYYY-MM-DD')}-to-` +
          `${selectedDatesOrder[1].format('YYYY-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: responseSkReports.map((o) => {
            return {
              skuId: o.skuId,
              ean: o.ean,
              product: o.product,
              variant: o.variant,
              dropPrice: o.dropPrice,
              total: o.total,
              quantity: o.quantity,
              orderNumber: o.orderNumber,
              warehouse: o.warehouse,
              transporter: o.transporter,
              deliveryDate: o.deliveryDate,
              deliveryStatus: o.deliveryStatus,
              dispatchStatus: o.dispatchStatus,
              dropShalom: o.dropShalom,
              type: o.type,
              emailDropShipper: o.emailDropShipper,
              documentDropShipper: o.documentDropShipper,
              businessDropShipper: o.businessDropShipper,
            };
          }),
        },
      });
    }
  }, [responseSkReports]);

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates);
  };

  const handleChangeEcom = (ecomId: string) => {
    setEcomSelected(ecomId);
  };

  useEffect(() => {
    if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER' ||
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'STORE'
    ) {
      setHiddenEcom(false);
      getCompaniesDrop();
    } else if (authUser.user.role.name === 'ADMIN_STORE') {
      setEcomSelected(`${authUser.user.company.id}`);
      setHiddenEcom(true);
    }

    if (
      authUser.user.role.name === 'SUPER_MASTER' ||
      authUser.user.role.name === 'STORE' ||
      authUser.user.role.name === 'ADMIN_STORE'
    ) {
      getTransporters();
      setHiddenTransp(false);
    } else if (
      authUser.user.role.name === 'ADMIN' ||
      authUser.user.role.name === 'MASTER'
    ) {
      setHiddenTransp(true);
      setTranspSelected(`${authUser.user.conveyor?.id}`);
    }
  }, []);

  const handleChangeTransporter = (id: string) => {
    setTranspSelected(id);
  };

  useEffect(() => {
    if (companies && companies.length > 0) {
      if (authUser.user.company.countryCode === 'PER') {
        setEcomSelected(`${companies[0].id}`);
      } else if (authUser.user.company.countryCode === 'BOL') {
        setEcomSelected(`${authUser.user.company.id}`);
      }
    }
  }, [companies]);

  return (
    <>
      <Spin spinning={loadingStockReports}>
        <Divider />

        <Title
          level={5}
          style={{
            marginBottom: '0',
            alignContent: 'center',
            padding: '.0rem 1.5rem',
          }}
        >
          Reporte de ventas de mis productos
        </Title>

        <div
          style={{
            padding: '.85rem 1.5rem',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          {hiddenTransp ? (
            <></>
          ) : (
            <>
              <Title level={5} style={{ marginBottom: '0' }}>
                Transportadora
              </Title>
              <Select
                onChange={handleChangeTransporter}
                showSearch
                optionFilterProp="key"
                loading={loadingTransporters}
                value={transpSelected}
                style={{ minWidth: '120px' }}
                filterOption={(input, option) =>
                  (option?.key as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  <Badge
                    style={{ marginRight: '.5rem' }}
                    color={'#000000'}
                    text=""
                  >
                    TODOS
                  </Badge>
                </Option>
                {transporters?.map((key) => (
                  <Option key={key.name} value={key.id}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <Title level={5} style={{ marginBottom: '0' }}>
            Filtrar fecha entrega
          </Title>

          <RangePicker
            format={dateFormat}
            defaultValue={[dayjs(new Date()), dayjs(new Date())]}
            onCalendarChange={handleCalendarChangeOrder}
            clearIcon={false}
          />

          {!hiddenEcom ? (
            <>
              <Title level={5} style={{ marginBottom: '0' }}>
                Ecommerce
              </Title>

              <Select
                placeholder="Ecommerce"
                value={ecomSelected}
                onChange={handleChangeEcom}
                loading={loadingCompanies}
                style={{ display: 'flex', width: '200px' }}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {authUser.user.company.countryCode === 'PER' ? (
                  <>
                    <Option key={-1} value={-1} label={'TODOS'}>
                      TODOS
                    </Option>
                    {companies.map((c) => (
                      <Option key={c.id} value={`${c.id}`} label={c.name}>
                        {c.name}
                      </Option>
                    ))}
                  </>
                ) : (
                  <>
                    {authUser.user.company.countryCode === 'BOL' ? (
                      <>
                        <Option
                          key={authUser.user.company.id}
                          value={`${authUser.user.company.id}`}
                          label={authUser.user.company.name}
                        >
                          {authUser.user.company.name}
                        </Option>
                      </>
                    ) : null}{' '}
                  </>
                )}
              </Select>
            </>
          ) : null}

          <Button
            type="primary"
            onClick={handleDownloadExcelOrders}
            loading={loadingStockReports}
          >
            Descargar reporte
          </Button>
        </div>
      </Spin>
    </>
  );
};

export default StockReport;
