import React, { FC, useEffect } from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  Radio,
  Select,
  Typography,
} from 'antd';
import {
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
  useTransportCostToUbigeo,
} from '../../../../../../services/location/location.service';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  useCountryEdit,
} from '../../../../../../services/pre-order/pre-order.edit';
import { useAuthStore } from '../../../../../../services/auth/auth.service';
import { useProductStore } from '../../../../../../services/product/product.store';
import { IOrderShopifySchema } from '../../../../../../services/order-shopify/order-shopify.schema';
import dayjs from 'dayjs';

const { Title } = Typography;

interface IOrderShopifyShippingFormProps {
  form: FormInstance<IOrderShopifySchema>;
  disabled: boolean;
  required: number;
}

const OrderShopifyShippingForm: FC<IOrderShopifyShippingFormProps> = ({
  form,
  disabled,
  required,
}) => {
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form);
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form);
  const districtId = Form.useWatch(['shipping', 'districtId'], form);
  const dispatchDate = Form.useWatch(['shipping', 'dispatchDate'], form);
  const enableDpto = Form.useWatch(['enableDpto'], form);
  const addDays = Form.useWatch(['addDays']);
  const hoursWork = Form.useWatch(['hoursWork']);
  const transportId = Form.useWatch(['transportId'], form);
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)
  const { productSearch } = useProductStore();
  const shippingByAgency = Form.useWatch(
    ['shipping', 'shippingByAgency'],
    form,
  );
  const { authUser } = useAuthStore();
  const { requiredByCountry } = useCountryEdit();
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } =
    useLocationLevel1();
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } =
    useLocationLevel2();
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } =
    useLocationLevel3();
  const currencySymbol = authUser.user.company.setting.currency.symbol;
  const { getTransport, transports } = useTransportCostToUbigeo();

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'districtId'], '')
  };

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId),
    });
    //form.setFieldValue(['shipping', 'provinceId'], '')
  };

  useEffect(() => {
    if (transportId && transports && transports.length > 0) {
      const transport = transports.find((t) => `${t.id}` === `${transportId}`);

      if (transport) {
        const warehouseIds = transport.coverages.map((c) => {
          const hourMinMain =
            c.warehouse.formatTimeDelivery &&
            c.warehouse.formatTimeDelivery.length > 0
              ? c.warehouse.formatTimeDelivery
              : '';

          const hourMinSecundary =
            c.warehouse.formatTimeSecundary &&
            c.warehouse.formatTimeSecundary.length > 0
              ? c.warehouse.formatTimeSecundary
              : '';

          const hourMinAgency =
            c.warehouse.formatTimeAgency &&
            c.warehouse.formatTimeAgency.length > 0
              ? c.warehouse.formatTimeAgency
              : '';

          return {
            id: c.warehouse.id,
            name: c.warehouse.name,
            addDays: c.addDays,
            companyId: c.warehouse.company.parentId,
            hoursWork:
              c.flagDeadLineTime === true ? hourMinMain : hourMinSecundary,
            hourMain: hourMinMain,
            hourSecundary: hourMinSecundary,
            hourAgency: hourMinAgency,
            returnCost:
              transport.isConveyorPremium === true
                ? c.returnCostPremium
                : c.returnCost,
            deliveryCost:
              transport.isConveyorPremium === true
                ? c.deliveryCostPremium
                : c.deliveryCost,
            typePattern: 1,
          };
        });

        form.setFieldValue(['shipping', 'dptoJson'], warehouseIds);

      }
    }
  }, [transportId]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!shippingByAgency) {
      if (authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER') {
        const newHour =
          hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[0]}`) : 0;
        const newMin =
          hoursWork.length > 0 ? parseInt(`${hoursWork.split(':')[1]}`) : 0;

        const now = dayjs().utcOffset(-5);
        const today = dayjs().startOf('day').add(newHour, 'h').add(newMin, 'm');

        if (now > today) {
          return (
            (current && current < dayjs().add(1, 'd').startOf('day')) ||
            current.day() === 0 || (current.date() === 25 && current.month() === 11)
            || (current.date() === 1 && current.month() === 0)
          );
        }

        return (
          current.isBefore(now.startOf('day').toDate()) ||
          current.day() === 0 ||
          (current.date() === 25 &&
            current.month() === 11) ||
          (current.date() === 1 &&
            current.month() === 0)
        );
        
      } else if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {

        const now = dayjs().utcOffset(-5);

        return current.isBefore(now.startOf('day').toDate());
        
      } else {
        return current && current < dayjs().startOf('day');
      }
    } else {
      return current && current < dayjs().startOf('day');
    }
  };

  useEffect(() => {
    if (dispatchDate && !shippingByAgency) {
      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        // free date delivery
      } else if (authUser.user.role.name === 'ADMIN_STORE' ||
        authUser.user.role.name === 'SELLER') {
        const days = addDays ? addDays : 0;
        const currentDay = dayjs(dispatchDate).utcOffset(-5);
        let newScheduleDate;

        if (currentDay.day() === 6) {
          // saturday
          const tempDays = parseInt(`${days}`);

          newScheduleDate = dayjs(dispatchDate).add(
            1 + (tempDays > 0 ? tempDays - 1 : 0),
            'd',
          );
        } else if (currentDay.day() === 5 && parseInt(`${days}`) === 3) {
          newScheduleDate = dayjs(dispatchDate).add(
            0 + parseInt(`${days}`),
            'd',
          );
        } else {
          //others days
          newScheduleDate = dayjs(dispatchDate).add(parseInt(`${days}`), 'd');
        }

        if (newScheduleDate.day() === 0) {
          newScheduleDate = dayjs(dispatchDate).add(
            1 + parseInt(`${days}`),
            'd',
          );
        }

        if (
          (newScheduleDate.date() === 25 && newScheduleDate.month() === 11) ||
          (newScheduleDate.date() === 1 && newScheduleDate.month() === 0)
        ) {
          newScheduleDate = dayjs(newScheduleDate).add(1, 'd');
        }

        form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate);
      }
    }
  }, [dispatchDate]);

  // 2900 => Arequipa 3292 => Cusco 3788 => La libertad 3926 => Lima

  useEffect(() => {
    if (!shippingByAgency) {
      form.setFieldValue(['shipping', 'departmentId'], undefined);
      form.setFieldValue(['shipping', 'provinceId'], undefined);
      form.setFieldValue(['shipping', 'districtId'], undefined);
      form.setFieldValue(['shipping', 'department'], '');
      form.setFieldValue(['shipping', 'dptoJson'], []);
      form.setFieldValue(['shipping', 'province'], '');
      form.setFieldValue(['shipping', 'district'], '');
      form.setFieldValue(['shipping', 'attachFile'], undefined);
      form.setFieldValue(['shipping', 'agencyName'], undefined);
      form.setFieldValue(['shipping', 'contactDocumenType'], undefined);
      form.setFieldValue(['shipping', 'contactDocumentNumber'], undefined);
      form.setFieldValue(['shipping', 'contactName'], undefined);
      form.setFieldValue(['shipping', 'contactPhone'], undefined);
      form.setFieldValue(['shipping', 'guideNumber'], undefined);
      form.setFieldValue(['shipping', 'keyCode'], undefined);
      form.setFieldValue(['shipping', 'addressPickUp'], undefined);
      form.setFieldValue(['shipping', 'shippingCost'], undefined);
      form.setFieldValue(['shipping', 'scheduleDate'], undefined);
      form.setFieldValue(['addDays'], 0);
    }
  }, [shippingByAgency]);

  useEffect(() => {
    const dptos = shippingByAgency
      ? locationsGeolevel1
        ? locationsGeolevel1
        : []
      : locationsGeolevel1
        ? locationsGeolevel1.filter((f) => f.flagContraentrega)
        : [];

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find((d) => `${d.id}` === `${departmentId}`);

      if (dpto) {
        getTransport(`${departmentId}`, '1');

        form.setFieldValue(['shipping', 'department'], dpto?.name);
        //form.setFieldValue(['shipping', 'dptoJson'], dpto.warehouseIds)
        handleChangeDepartment(`${dpto.id}`);
      }
    }
  }, [departmentId, locationsGeolevel1]);

  useEffect(() => {
    const provinces = shippingByAgency
      ? locationsGeolevel2
        ? locationsGeolevel2
        : []
      : locationsGeolevel2
        ? locationsGeolevel2.filter((f) => f.flagContraentrega)
        : [];

    if (provinceId && provinces.length > 0) {
      const prov = provinces.find((d) => `${d.id}` === `${provinceId}`);

      if (prov) {
        getTransport(`${departmentId}`, '2', `${provinceId}`);

        form.setFieldValue(['shipping', 'province'], prov?.name);

        handleChangeProvince(`${prov.id}`);
      }
    }
  }, [provinceId, locationsGeolevel2]);

  useEffect(() => {
    const districts = shippingByAgency
      ? locationsGeolevel3
        ? locationsGeolevel3
        : []
      : locationsGeolevel3
        ? locationsGeolevel3.filter((f) => f.flagContraentrega)
        : [];

    if (districtId && districts.length > 0) {
      const dist = districts.find((d) => `${d.id}` === `${districtId}`);

      if (dist) {
        getTransport(`${departmentId}`, '3', `${provinceId}`, `${districtId}`);
        form.setFieldValue(['shipping', 'district'], dist?.name);
      }
    }
  }, [districtId, locationsGeolevel3]);

  useEffect(() => {
    getLocationsGeoLevel1();
  }, []);

  return (
    <>
      <Title level={4}>Entrega</Title>

      <Form.Item
        label="Departamento"
        name={['shipping', 'departmentId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeDepartment}
          showSearch
          optionFilterProp="label"
          disabled={disabled === false ? enableDpto : disabled}
          loading={loadingGeo1}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel1
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel1?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
        />
      </Form.Item>

      <Form.Item
        label="Provincia"
        name={['shipping', 'provinceId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          onChange={handleChangeProvince}
          showSearch
          optionFilterProp="label"
          loading={loadingGeo2}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel2
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel2?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item
        label="Distrito"
        name={['shipping', 'districtId']}
        rules={[
          {
            required: required === 1 ? false : true,
            message: '',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          loading={loadingGeo3}
          filterOption={(input, option) =>
            (option?.label as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={
            !shippingByAgency
              ? locationsGeolevel3
                  ?.filter((f) => f.flagContraentrega)
                  .map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))
              : locationsGeolevel3?.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))
          }
          disabled={disabled}
        />
      </Form.Item>

      {transports && transports.length > 0 ? (
        <>
          <Form.Item
            label="Courier"
            name={['transportId']}
            rules={[{ required: true, message: '' }]}
          >
            <Radio.Group disabled={enableDpto}>
              {transports.map((e) => (
                <>
                  <Radio value={e.id} 
                    disabled={!warehouseToday ? 
                      false
                      : !e.coverages.find(ew => ew.warehouse.name === (warehouseToday))}>
                    <img
                      src={e.urlImage}
                      style={{ width: '100px', height: '50px' }}
                    ></img>

                    { warehouseToday && warehouseToday.length > 0 ? <>

                      {e.coverages.find(ew => ew.warehouse.name === (warehouseToday)) ? <>

                        Entrega: {currencySymbol}
                        {e.isConveyorPremium === true ? 
                          e.coverages.find(ew => ew.warehouse.name === (warehouseToday))?.deliveryCostPremium : 
                          e.coverages.find(ew => ew.warehouse.name === (warehouseToday))?.deliveryCost}
                        {' '}
                        | Retorno: {currencySymbol}
                        {e.isConveyorPremium === true ? 
                          e.coverages.find(ew => ew.warehouse.name === (warehouseToday))?.returnCostPremium : 
                          e.coverages.find(ew => ew.warehouse.name === (warehouseToday))?.returnCost}
                      
                      </> : <></>}

                    </> : <></>}
                  </Radio>
                </>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      ) : null}

      {!shippingByAgency && productSearch && productSearch.length > 0 ? (
        <>
          <Form.Item
            label="F. despacho"
            name={['shipping', 'dispatchDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: 'Seleccionar una fecha de despacho',
              },
            ]}
          >
            <DatePicker
              disabled={disabled}
              inputReadOnly={true}
              showNow={false}
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label="F. entrega aprox."
            name={['shipping', 'scheduleDate']}
            rules={[
              {
                required: required === 1 || required === 2 ? false : true,
                message: '',
              },
            ]}
          >
            <DatePicker
              disabled
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      ) : null}

      {shippingByAgency ? (
        <>
          <Form.Item
            label="F. entrega programada"
            name={['shipping', 'scheduleDate']}
            hidden={shippingByAgency}
            rules={[
              {
                required: !shippingByAgency
                  ? required === 1 || required === 2
                    ? false
                    : true
                  : false,
                message: 'Seleccionar una fecha de entrega',
              },
            ]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              inputReadOnly={true}
              showNow={false}
              disabled={disabled}
            />
          </Form.Item>
        </>
      ) : null}

      <Form.Item
        label="Dirección 1"
        name={['shipping', 'address1']}
        rules={[
          {
            required: !shippingByAgency
              ? required === 1 || required === 2
                ? false
                : true
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Dirección 2" name={['shipping', 'address2']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Referencia"
        name={['shipping', 'reference']}
        hidden={shippingByAgency}
        rules={[
          {
            required: !shippingByAgency
              ? requiredByCountry
                ? required === 0
                  ? true
                  : false
                : false
              : false,
            message: '',
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="GPS"
        name={['shipping', 'gps']}
        hidden={shippingByAgency}
        rules={[
          {
            required: !shippingByAgency
              ? requiredByCountry
                ? required === 0
                  ? true
                  : false
                : false
              : false,
            message: '',
            pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
          },
        ]}
      >
        <Input placeholder="Latitud,Longitud" disabled={disabled} />
      </Form.Item>

      {!disabled ? (
        <>
          <Form.Item label="GPS" hidden={shippingByAgency}>
            Solicítalo al cliente por WhatsApp o
            <a target="_blank" rel="noreferrer" href="https://bit.ly/3wqggn9">
              {' '}
              ver TUTORIAL
            </a>
          </Form.Item>
        </>
      ) : null}

      <Form.Item hidden name={['shipping', 'department']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'dptoJson']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'district']}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item hidden name={['shipping', 'province']}>
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default OrderShopifyShippingForm;
