import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Select, Space, Typography } from 'antd';
import Slider, { SliderMarks } from 'antd/lib/slider';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCategories } from '../../../../../services/category/category.service';
import { useWarehousePublicByCountry } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useCatalogsByCompany } from '../../../../../services/catalog/catalog.service';
import { useCompanyDrop } from '../../../../../services/order-report-created/order-report-created.service';
import {
  TBestSellersFiltersArgs,
  TProductGlobalFiltersArgs,
} from '../../../../../services/product-global/product-global.interface';
import { PageHeader } from '../../../../../components';
import { FilterOutlined } from '@ant-design/icons';
import { useBreakpoint } from '../../../../../hooks';

const { Search } = Input;
const { Option } = Select;

type TGeneralCatalogFiltersProps = {
  onProductGlobalFilterChange: (args: TProductGlobalFiltersArgs) => void;
  onBestSellersFilterChange: (args: TBestSellersFiltersArgs) => void;
};

export const GeneralCatalogFilters: FC<TGeneralCatalogFiltersProps> = ({
  onProductGlobalFilterChange,
  onBestSellersFilterChange,
}) => {
  const { getCatalogsByCompany, loadingCatalogsStore, catalogs } =
    useCatalogsByCompany();
  const { getCompaniesDrop, companies, loadingCompanies } = useCompanyDrop();
  const { getCategories, categories, loadingCategory } = useCategories();
  const { getWarehouses, loadingWarehouses, warehouses } =
    useWarehousePublicByCountry();

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'xs';
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const { authUser } = useAuthStore();
  const [hiddenCatalog, setHiddenCatalog] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(500);
  const [orderProd, setOrderProd] = useState('desc');
  const [catalogId, setCatalogId] = useState<number>();
  const [categoryIdSelected, setCategoryIdSelected] = useState<number | 'ALL'>(
    'ALL',
  );
  const [warIdSelected, setWarIdSelected] = useState<number | 'ALL'>('ALL');
  const [companyDropId, setCompanyDropId] = useState<number | 'ALL'>('ALL');

  const marks: SliderMarks = {
    0: {
      style: {
        fontSize: '11px',
      },
      label: `Mín: ${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}0`,
    },
    150: {
      style: {
        fontSize: '11px',
      },
      label: `${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}150`,
    },
    300: {
      style: {
        color: '#000',
        fontSize: '11px',
      },
      label: `Máx: ${authUser.user.company.countryCode === 'PER' ? 'S/' : 'Bs'}300`,
    },
  };

  const onRangePriceChange = (value: number[]) => {
    if (Array.isArray(value)) {
      setPriceMin(value[0]);
      setPriceMax(value[1]);
    }
  };

  useEffect(() => {
    getCatalogsByCompany();
    getCompaniesDrop();
  }, []);

  useEffect(() => {
    if (catalogs?.length) {
      getCategories();
      getWarehouses();

      if (
        authUser.user.role.name === 'ADMIN' ||
        authUser.user.role.name === 'MASTER'
      ) {
        setHiddenCatalog(false);
      } else {
        setHiddenCatalog(catalogs.length === 1);
      }

      setCatalogId(catalogs[0].id);
    }
  }, [catalogs]);

  useEffect(() => {
    if (catalogId) {
      onProductGlobalFilterChange({
        search: searchText,
        priceMax,
        priceMin,
        orderBy: orderProd,
        catalogId: catalogId ? `${catalogId}` : '',
        categoryId: categoryIdSelected === 'ALL' ? '' : `${categoryIdSelected}`,
        warehouseId: warIdSelected === 'ALL' ? '' : `${warIdSelected}`,
        companyId: companyDropId === 'ALL' ? '' : `${companyDropId}`,
      });
    }
  }, [
    catalogId,
    searchText,
    priceMax,
    priceMin,
    orderProd,
    categoryIdSelected,
    warIdSelected,
    companyDropId,
  ]);

  useEffect(() => {
    if (catalogId) {
      onBestSellersFilterChange({
        catalogId: catalogId ? `${catalogId}` : '',
        warehouseId: warIdSelected === 'ALL' ? '' : `${warIdSelected}`,
        companyId: companyDropId === 'ALL' ? '' : `${companyDropId}`,
      });
    }
  }, [catalogId, warIdSelected, companyDropId]);

  useEffect(() => {
    setShowFilters(!isMobile);
  }, [breakpoint]);

  return (
    <PageHeader>
      <Space wrap>
        <Space>
          <Search
            placeholder="Buscar producto"
            onSearch={setSearchText}
            enterButton
            style={{ width: '220px' }}
            allowClear
          />
          {isMobile && (
            <Button
              color="primary"
              variant="filled"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <FilterOutlined />
              Filtrar
            </Button>
          )}
        </Space>

        {showFilters && (
          <Space wrap>
            <div>
              <Typography.Text type="secondary">Almacén:</Typography.Text>
              <br />
              <Select
                optionFilterProp="label"
                loading={loadingWarehouses}
                style={{ minWidth: '150px' }}
                onChange={setWarIdSelected}
                value={warIdSelected}
                showSearch
                filterOption={(input, option) =>
                  (option?.label as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  TODOS
                </Option>
                {warehouses.map((c) => {
                  return (
                    <Option key={c.id} value={c.id} label={c.name}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div>
              <Typography.Text type="secondary">Categorías:</Typography.Text>
              <br />
              <Select
                optionFilterProp="label"
                loading={loadingCategory}
                style={{ minWidth: '150px' }}
                showSearch
                onChange={setCategoryIdSelected}
                value={categoryIdSelected}
                filterOption={(input, option) =>
                  (option?.label as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL">
                  TODOS
                </Option>
                {categories.map((c) => {
                  return (
                    <Option key={c.id} value={c.id} label={c.name}>
                      {c.name}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div>
              <Typography.Text type="secondary">Ordernar por:</Typography.Text>
              <br />
              <Select
                optionFilterProp="label"
                style={{ minWidth: '150px' }}
                onChange={setOrderProd}
                value={orderProd}
                filterOption={(input, option) =>
                  (option?.label as string)
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="desc" value="desc" label={'Más Recientes'}>
                  Más Recientes
                </Option>
                <Option key="asc" value="asc" label={'Más Antiguos'}>
                  Más Antiguos
                </Option>
              </Select>
            </div>

            {catalogs.length > 0 && hiddenCatalog === false ? (
              <div>
                <Typography.Text type="secondary">Catálogo</Typography.Text>
                <br />
                <Select
                  optionFilterProp="label"
                  style={{ minWidth: '150px' }}
                  onChange={setCatalogId}
                  showSearch
                  value={catalogId}
                  loading={loadingCatalogsStore}
                  filterOption={(input, option) =>
                    (option?.label as string)
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {catalogs.map((c) => {
                    return (
                      <Option key={c.id} value={c.id} label={c.name}>
                        {c.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}

            {companies?.length > 0 ? (
              <div>
                <Typography.Text type="secondary">Proveedor</Typography.Text>
                <br />
                <Select
                  optionFilterProp="label"
                  style={{ minWidth: '150px' }}
                  onChange={setCompanyDropId}
                  value={companyDropId}
                  showSearch
                  loading={loadingCompanies}
                  filterOption={(input, option) =>
                    (option?.label as string)
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Proveedor"
                >
                  <Option key="ALL" value="ALL">
                    TODOS
                  </Option>
                  {companies.map((c) => {
                    return (
                      <Option key={c.id} value={`${c.id}`} label={c.name}>
                        {c.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}

            <div>
              <Typography.Text type="secondary">Precio Drop:</Typography.Text>
              <br />
              <Slider
                range
                marks={marks}
                min={0}
                max={300}
                defaultValue={[0, 150]}
                style={{
                  width: '200px',
                  margin: '0 1.5rem',
                  paddingBottom: '2rem',
                }}
                onAfterChange={onRangePriceChange}
              />
            </div>
          </Space>
        )}
      </Space>
    </PageHeader>
  );
};
