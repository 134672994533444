import React from 'react';
import { Button, Flex, Spin } from 'antd';
import { useEffect } from 'react';
import { useIntersectionObserver } from '../../hooks';
import { LoadingOutlined } from '@ant-design/icons';

type TActionIntersectionObserverProps = {
  loading?: boolean;
  onVisible: () => void;
  onAction?: () => void;
};

export const ActionIntersectionObserver: React.FC<
  TActionIntersectionObserverProps
> = ({ loading, onVisible, onAction }) => {
  const hasIntersectionObserver = 'IntersectionObserver' in window;
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  if (!hasIntersectionObserver) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{ padding: '1.5rem 0 6rem' }}
      >
        <Button
          onClick={onAction}
          color="primary"
          variant="outlined"
          loading={loading}
        >
          + Ver más
        </Button>
      </Flex>
    );
  }

  useEffect(() => {
    if (isIntersecting && !loading) {
      onVisible();
    }
  }, [isIntersecting, onVisible, loading]);

  if (loading)
    return (
      <Flex
        justify="center"
        align="center"
        style={{ padding: '1.5rem 0 6rem' }}
      >
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </Flex>
    );

  return <div ref={ref} style={{ padding: '3rem' }}></div>;
};
