import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import {
  ICodeAuthRequest,
  IPasswordSchema,
  IResetPasswordSchema,
} from '../../../../services/auth/auth.interface';
import {
  useSendCodeAuthResetPassword,
  useVerifyCodeAuth,
} from '../../../../services/auth/auth.service';
import { formLayout } from '../../../../config/forms-layout';

interface IPassVerifyFormProps {
  loadingChangePassword: boolean;
  resetPassword: (request: IResetPasswordSchema) => void;
}

const PassVerifyForm: FC<IPassVerifyFormProps> = ({
  loadingChangePassword,
  resetPassword,
}) => {
  const [form] = Form.useForm<IResetPasswordSchema>();
  const emailText = Form.useWatch(['email'], form);
  const userText = Form.useWatch(['userEmail'], form);
  const codeAuthText = Form.useWatch(['codeAuth'], form);
  const { sendCodeAuth, response, loading } = useSendCodeAuthResetPassword();
  const { verifyCodeAuth, responseCodeAuth, loadingCodeAuth } =
    useVerifyCodeAuth();
  const [sendCode, setSendCode] = useState(false);
  const [validateCode, setValidateCode] = useState(false);

  const onSendCodeAuth = () => {
    if (userText && emailText) {
      const request: IPasswordSchema = {
        userName: userText,
        email: emailText,
      };

      sendCodeAuth(request);
    } else {
      form.validateFields(['userEmail']);
      form.validateFields(['email']);
    }
  };

  const verifyCode = () => {
    if (codeAuthText) {
      const request: ICodeAuthRequest = {
        userId: response?.userId,
        codeAuth: codeAuthText,
      };

      verifyCodeAuth(request);
    } else {
      form.validateFields(['codeAuth']);
    }
  };

  useEffect(() => {
    if (response) {
      setSendCode(true);
      form.setFieldValue('userId', response.userId);
      message.info(
        `Se le envio el código de recuperación de contraseña a su correo ${response.email}.`,
      );
    }
  }, [response]);

  useEffect(() => {
    if (responseCodeAuth) {
      if (responseCodeAuth.isVerify) {
        setValidateCode(true);
        message.info('Código de recuperación verificado exitósamente.');
      }
    }
  }, [responseCodeAuth]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="ResetPasswordForm"
        labelWrap
        onFinish={resetPassword}
        {...formLayout}
      >
        <Form.Item name="userId" hidden>
          <Input hidden />
        </Form.Item>

        <div
          style={{
            textAlign: 'center',
            marginBottom: '30px',
          }}
        >
          <Typography.Title level={3}>Restablecer contraseña</Typography.Title>
        </div>

        <Form.Item
          label={<span>Usuario Aliclik</span>}
          name="userEmail"
          rules={[{ required: true, message: 'Ingrese su usuario' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span>Correo electrónico</span>}
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingrese su correo electrónico',
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {sendCode ? (
          <>
            {validateCode ? (
              <>
                <Form.Item
                  label={<span>Nueva contraseña</span>}
                  name={['password']}
                  rules={[
                    { required: true, message: 'Ingrese su nueva contraseña' },
                  ]}
                >
                  <Input />
                </Form.Item>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="primary"
                    loading={loadingChangePassword}
                    htmlType="submit"
                  >
                    Actualizar contraseña
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Form.Item
                  label={<span>Ingrese el código de recuperación</span>}
                  name="codeAuth"
                  rules={[
                    {
                      required: true,
                      message:
                        'Ingrese su código recuperación enviado a su correo',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    type="primary"
                    loading={loadingCodeAuth}
                    onClick={() => verifyCode()}
                  >
                    Validar código de recuperación de contraseña
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                type="primary"
                loading={loading}
                onClick={() => onSendCodeAuth()}
              >
                Enviar código de recuperación de contraseña
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default PassVerifyForm;
