import React, { ComponentType, FC, useEffect } from 'react';
import { Spin } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthStore, useVerify } from '../services/auth/auth.service';
import { ROUTES } from './routes.param';
import { LoadingOutlined } from '@ant-design/icons';

type TPrivateRouteProps = {
  Component: ComponentType;
};

const PrivateRoute: FC<TPrivateRouteProps> = ({ Component }) => {
  const navigate = useNavigate();
  const { authUser } = useAuthStore();
  const { getVerify, loading } = useVerify();

  useEffect(() => {
    if (!authUser) {
      navigate(ROUTES.SIGN_IN.PATH);
    } else {
      getVerify();
    }
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '7rem 0' }}>
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </div>
    );
  }

  return authUser ? <Component /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
