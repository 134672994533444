import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

export interface State {
  displayDrawer: boolean;
  drawerView: string | null;
  drawerTitle: string | undefined;
}

const initialState = {
  displayDrawer: false,
  drawerView: null,
  drawerTitle: undefined,
};

type Action =
  | {
      type: 'OPEN_DRAWER';
      view: string;
      title: string | undefined;
    }
  | {
      type: 'CLOSE_DRAWER';
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DrawerContext = createContext<State | any>(initialState);
DrawerContext.displayName = 'DrawerContext';

function drawerReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_DRAWER': {
      return {
        ...state,
        drawerView: action.view,
        displayDrawer: true,
        drawerTitle: action.title,
      };
    }

    case 'CLOSE_DRAWER': {
      return {
        ...state,
        displayDrawer: false,
      };
    }
  }
}

interface IDrawerProviderProps {
  children: ReactNode;
}
export const DrawerProvider: FC<IDrawerProviderProps> = (props) => {
  const [state, dispatch] = useReducer(drawerReducer, initialState);
  const openDrawer = (view: string, title?: string) =>
    dispatch({ type: 'OPEN_DRAWER', view, title });
  const closeDrawer = () => dispatch({ type: 'CLOSE_DRAWER' });

  const value = useMemo(
    () => ({
      ...state,
      openDrawer,
      closeDrawer,
    }),
    [state],
  );

  return <DrawerContext.Provider value={value} {...props} />;
};

/**
 * @deprecated Please use `react-modal-hook` library instead
 */
export const useDrawer = () => {
  return useContext(DrawerContext);
};
