import React, { FC, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Divider,
  Form,
  Select,
  Input,
  Upload,
  message,
  Typography,
  Radio,
} from 'antd';
import {
  OrderAgencyPart,
  OrderProductAgencyPart,
  OrderAgencyShippingPart,
  OrderCustomerAgencyPart,
} from './parts';
import { useProductStore } from '../../../../../services/product/product.store';
import TextArea from 'antd/lib/input/TextArea';
import {
  TRACKING_STATUS_LABEL,
  TRACKING_STATUS_LIST,
} from '../../../../../services/order-create/order.params';
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params';
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema';
import { useUserSupSeller } from '../../../../../services/order-create/order.edit';
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { ISellerResponse } from '../../../../../services/user-seller-list/user-seller-list.interface';
import { PAYMENT_TYPE_LABEL } from '../../../../../services/payment-create/payment.params';
import { UploadOutlined } from '@ant-design/icons';
import { storage } from '../../../../../libs/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { IOrderShopify } from '../../../../../services/order-shopify/order-shopify.interface';

const { Option, OptGroup } = Select;
const { Title } = Typography;

interface IOrderEditFormProps {
  initialValues: IOrderShopify | null;
  onSubmit: (data: IOrderUpdateSchema) => void;
}

const OrderShopifyAgencyForm: FC<IOrderEditFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const [form] = Form.useForm<IOrderUpdateSchema>();
  const { setProductEmpty } = useProductStore();
  const { isUser } = useUserSupSeller();
  const { getSellers, sellers } = useSellerList();
  const { companyGlobal } = useCompanyStore();
  const seller = Form.useWatch(['user', 'fullname'], form);
  const [useCallStatus, setUseCallStatus] = useState(true);
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState<any>();
  const [url, setUrl] = useState('');
  const voucherAgency = Form.useWatch(['voucherPayAgency'], form);
  const managementType = Form.useWatch(['managementType'], form);
  const flagManagement = Form.useWatch(['flagManagementType'], form);
  const trackingStatus = Form.useWatch(['trackingStatus'], form);
  const { authUser } = useAuthStore();
  const [blockSave, setBlockSave] = useState(false);
  const blockFields = Form.useWatch(['blockFields'], form);

  useEffect(() => {
    if (seller && sellers.length > 0) {
      const s = sellers.find((i: ISellerResponse) => i.fullname == seller);

      form.setFieldValue(['user', 'id'], s?.id);
    }
  }, [seller, sellers]);

  useEffect(() => {
    form.setFieldValue(['voucherPayAgency'], url);
  }, [url]);

  useEffect(() => {
    setUseCallStatus(true);
    if (initialValues === null) {
      form.setFieldValue(['shipping', 'shippingByAgency'], true);
    } else {
      if (isUser) {
        getSellers(companyGlobal ? `${companyGlobal.id}` : '');
      }
    }

    if (initialValues) {
      setProductEmpty();

      const shipping = initialValues.shipping;

      let phone = '';

      if (initialValues.shipping.phone.length > 0) {
        phone = initialValues.shipping.phone.replace('+', '');
      }

      form.setFieldsValue({
        callStatus: initialValues.status,
        trackingStatus: TRACKING_STATUS_LIST.TO_REGISTER,
        createdAtShopify: initialValues.createdAt,
        prodShopifyDetail: initialValues?.items
          .map((p) => `${p.quantity} ${p.name}`)
          .join(', '),
        note: initialValues.note,
        orderNumberShopify: initialValues.orderNumber,
        channel: 'Shopify',
        customer: {
          name: initialValues.customer.firstName,
          lastName: initialValues.customer.lastName,
          phone: phone,
        },
        shipping: {
          address1: shipping.address,
          address2: shipping.reference,
          shippingByAgency: true,
        },
      });

      form.setFieldValue('callStatus', 'CONFIRMED');
      form.setFieldValue('status', 'PENDING_DELIVERY');
      form.setFieldValue('trackingStatus', 'TO_REGISTER');
      form.setFieldValue('managementType', 'OWN');
    }
  }, [initialValues]);

  function handleBeforeUpload(file: any) {
    setFile(file);
    if (!file) {
      message.success('Debe seleccionar un archivo');

      return;
    }

    const storageRef = ref(storage, `/files_agency/${file?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        setPercent(percent);
      },
      (error) => {
        console.log('error', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          console.log('url image', url);
        });
      },
    );

    return false;
  }

  useEffect(() => {
    if (trackingStatus) {
      if (managementType === 'PARTNER') {
        if (trackingStatus === TRACKING_STATUS_LIST.TO_REGISTER) {
          setUseCallStatus(false);
        } else {
          setUseCallStatus(true);
        }

        if (
          authUser.user.role.name === 'STORE' ||
          authUser.user.role.name === 'ADMIN'
        ) {
          setBlockSave(false);
          form.setFieldValue('blockFields', true);
        } else {
          form.setFieldValue('blockFields', false);
        }
      }
    }
  }, [trackingStatus]);

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="order"
        labelWrap
        onFinish={onSubmit}
        initialValues={{
          channel: 'Shopify',
        }}
      >
        <Form.Item
          name={['blockFields']}
          label="Bloquear campos por roles"
          hidden
        >
          <Radio.Group
            options={[
              { label: 'No', value: false },
              { label: 'Si', value: true },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>

        <Form.Item
          name="callStatus"
          label="Estado llamada"
          rules={[{ required: true, message: '' }]}
        >
          <Select placeholder="Seleccione una opción" disabled={useCallStatus}>
            {ORDER_STATUS_LIST_CONFIRMED.map((status) => (
              <Option key={status.value} value={status.value}>
                {status.label}
              </Option>
            ))}
            <OptGroup label="ORDEN">
              {ORDER_STATUS_LIST_NOT_AVAILABLE.map((status) => (
                <Option key={status.value} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>

        <Form.Item name="status" label="Estado" hidden>
          <input />
        </Form.Item>

        {managementType === 'PARTNER' ? (
          <>
            <Form.Item name="trackingStatus" label="Estado de seguimiento">
              <Select
                placeholder="Seleccione una opción"
                disabled={useCallStatus}
              >
                <Option
                  key={TRACKING_STATUS_LIST.TO_REGISTER}
                  value={TRACKING_STATUS_LIST.TO_REGISTER}
                >
                  <Badge style={{ marginRight: '.5rem' }} text="" />
                  {
                    TRACKING_STATUS_LABEL[TRACKING_STATUS_LIST.TO_REGISTER]
                      .label
                  }
                </Option>
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name="trackingStatus" label="Estado de seguimiento">
              <Select
                placeholder="Seleccione una opción"
                disabled={useCallStatus}
              >
                {Object.keys(TRACKING_STATUS_LABEL).map((key) => (
                  <Option key={key} value={key}>
                    <Badge style={{ marginRight: '.5rem' }} text="" />
                    {TRACKING_STATUS_LABEL[key].label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item name={['createdAtShopify']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['prodShopifyDetail']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['orderNumberShopify']} hidden>
          <Input />
        </Form.Item>

        <Form.Item label="Nota" name="note">
          <TextArea rows={1} />
        </Form.Item>

        <Form.Item label="Costo de entrega" name="shippingCost" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="channel"
          label="Canal"
          rules={[{ required: true, message: '' }]}
        >
          <Select
            disabled={!!initialValues}
            placeholder="Seleccione una opción"
          >
            <Option value="Shopify">Shopify</Option>
            <Option value="Whatsapp">Whatsapp</Option>
            <Option value="Llamada">Llamada</Option>
            <Option value="Redes sociales">Redes sociales</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo de gestión"
          name={['managementType']}
          rules={[{ required: true, message: '' }]}
        >
          <Select
            disabled={blockSave || flagManagement}
            placeholder="Seleccione una opción"
          >
            <Option value="OWN">Propia</Option>
            <Option value="PARTNER">Drop Shalom</Option>
          </Select>
        </Form.Item>

        {managementType === 'PARTNER' ? (
          <>
            <Title level={5} style={{ textAlign: 'right' }}>
              Recuerda que el registro de tu guía lo tienes que hacerlo por
              Shalom PRO
            </Title>
          </>
        ) : null}

        <Form.Item name={['user', 'id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['dispatchStatus']} hidden>
          <Input />
        </Form.Item>

        {initialValues && isUser ? (
          <>
            <Form.Item name={['user', 'fullname']} label="Vendedor">
              <Select
                placeholder="Seleccione un vendedor"
                disabled={blockFields}
              >
                {sellers.map((s: ISellerResponse) => (
                  <Option key={s.id} value={s.fullname}>
                    {s.fullname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : null}

        <Divider />

        <OrderCustomerAgencyPart disabled={blockFields} />

        <OrderProductAgencyPart form={form} />

        {managementType === 'PARTNER' ? (
          <>
            <Divider />

            <Form.Item
              name={['voucherPayAgency']}
              label="Subir voucher de pago:"
            >
              <Upload
                name="file"
                beforeUpload={handleBeforeUpload}
                maxCount={1}
                fileList={[]}
                disabled={blockFields}
              >
                <Button disabled={blockFields}>
                  <UploadOutlined /> {file ? file.name : ''} {percent} %
                </Button>
              </Upload>
              {voucherAgency?.length !== 0 ? (
                <>
                  <a
                    href={voucherAgency}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    Ver voucher
                  </a>
                </>
              ) : null}
            </Form.Item>
          </>
        ) : null}

        <Divider />

        <Form.Item
          label="Tipo de pago"
          name={['paymentType']}
          rules={[{ required: true, message: 'Seleccione un tipo de pago' }]}
        >
          <Select placeholder="Tipo de pago" disabled={blockFields}>
            {Object.keys(PAYMENT_TYPE_LABEL).map((key) => (
              <Option key={key} value={key}>
                {PAYMENT_TYPE_LABEL[key].label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <OrderAgencyShippingPart form={form} />
        <Divider />

        <OrderAgencyPart form={form} />
        <Divider />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            hidden={blockSave}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OrderShopifyAgencyForm;
