import { useAuthStore } from '../auth/auth.service'

export const useUserSupSeller = () => {
  const { authUser } = useAuthStore()
  let isUser = false

  if (authUser.user.role?.name === 'ADMIN_STORE') {
    isUser = true
  }
  
  return { isUser }
}

export const useUserCompanyList = () => {

  const { authUser } = useAuthStore()
  let isFilterCompany = false

  if (authUser.user.role.name === 'SUPER_MASTER' || authUser.user.role.name === 'ADMIN' || 
    authUser.user.role.name === 'SUP_SELLER' || authUser.user.role.name === 'SUP_MOTORIZED' ||
    authUser.user.role.name === 'MASTER'
  ) {

    isFilterCompany = false

    return { isFilterCompany }

  } else if (authUser.user.role.name === 'ADMIN_STORE') {

    isFilterCompany = true

    return { isFilterCompany }

  } else return { isFilterCompany }

}

export const useUserListTransport = () => {
  const { authUser } = useAuthStore()
  let isComboxTransp = false

  if (authUser.user.role.name === 'SUPER_MASTER' || authUser.user.role.name === 'STORE') {

    isComboxTransp = false

    return { isComboxTransp }

  } else if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ||
    authUser.user.role.name === 'STORE_CENTRAL'
  ) {

    isComboxTransp = true

    return { isComboxTransp }

  } else return { isComboxTransp }

}

export const useUserMaster = () => {
  let isMaster = false
  const { authUser } = useAuthStore()
  
  if (authUser.user.role?.name === 'MASTER' || authUser.user.role?.name === 'SUPER_MASTER') {
    isMaster = true
  }

  return { isMaster }
}

export const useUserAdmin = () => {
  let isAdmin = false
  const { authUser } = useAuthStore()

  if (authUser.user.role?.name === 'ADMIN' || authUser.user.role?.name === 'SUPER_MASTER') {
    isAdmin = true
  }

  return { isAdmin }
}

export const useUserMasterAdmin = () => {
  let rolUserMaster = false
  const { authUser } = useAuthStore()
  
  if (authUser.user.role?.name === 'ADMIN' || authUser.user.role?.name === 'MASTER' 
    || authUser.user.role?.name === 'SUPER_MASTER') {
    rolUserMaster = true
  }

  return { rolUserMaster }
}

export const useOrderConfirmedEdit = () => {
  const { authUser } = useAuthStore()
  let companyUser = 0
  let isEditable = false

  if (authUser.user.role?.name === 'ADMIN_STORE' || authUser.user.role?.name === 'SELLER') {
    companyUser = 2
  } else if (authUser.user.role?.name === 'ADMIN' || authUser.user.role?.name === 'SUP_SELLER') {
    companyUser = 1
    isEditable = true
  } else if (authUser.user.role?.name === 'SUP_MOTORIZED') {
    companyUser = 1
  } else if (authUser.user.role?.name === 'MASTER') {
    companyUser = 3
  }

  return { companyUser, isEditable }
}

export const useOrderDeliveredEdit = () => {
  const { authUser } = useAuthStore()
  let companyUser = 0

  if (authUser.user.role?.name === 'ADMIN_STORE' || authUser.user.role?.name === 'SELLER') {
    companyUser = 2
  } else if (authUser.user.role?.name === 'ADMIN' || authUser.user.role?.name === 'SUP_SELLER' ||
    authUser.user.role?.name === 'SUP_MOTORIZED'
  ) {
    companyUser = 1
  } else if (authUser.user.role?.name === 'MASTER') {
    companyUser = 3
  }

  return { companyUser }
}

export const useOrderDeliveryEdit = () => {
  const { authUser } = useAuthStore()

  const disabledEdit = authUser.user.role?.name === 'MOTORIZED'
    || authUser.user.role?.name === 'LIQUIDATOR'

  return { disabledEdit }
}

export const useProductDeliveryEdit = () => {
  const { authUser } = useAuthStore()

  const disabledEdit = authUser.user.role?.name === 'ADMIN'
    || authUser.user.role?.name === 'ADMIN_STORE' || authUser.user.role?.name === 'SELLER'
    || authUser.user.role?.name === 'SUP_SELLER'

  return { disabledEdit }
}
