import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Select, Switch, Typography } from 'antd';
import { useRolesAll } from '../../../../../services/role-list/role-list.service';
import { formItemLayout, formLayout } from '../../../../../config/forms-layout';
import { RichEditor } from '../../../../../components';
import { IFrequentQuestionCreateSchema } from '../../../../../services/frequent-question-create/frequent-question-create.schema';
import { IFrequentQuestion } from '../../../../../services/frequent-question-list/frequent-question.interface';

interface IFaqEditFormProps {
  initialValue?: IFrequentQuestion;
  onSubmit: (data: IFrequentQuestionCreateSchema) => void;
}

export const FaqEditForm: FC<IFaqEditFormProps> = ({
  initialValue,
  onSubmit,
}) => {
  const [form] = Form.useForm<IFrequentQuestionCreateSchema>();
  const { getRoles, loading, roles } = useRolesAll();

  // Answer validation
  const answerValue = Form.useWatch('answer', form);
  const answerError = answerValue?.length > 1500;

  const richTextModules = {
    toolbar: [
      ['bold', 'italic', 'underline'], // Estilos básicos
      ['link'], // Insertar enlace
      [{ list: 'ordered' }, { list: 'bullet' }], // Listas
    ],
  };

  const onChangeRichEditor = (value: string) => {
    form.setFieldsValue({ answer: value });
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({
        id: initialValue?.id,
        question: initialValue?.question,
        answer: initialValue?.answer,
        module: initialValue?.module,
        roles:
          initialValue?.roles?.split(',')?.map((role) => role.trim()) || [],
        position: initialValue?.position,
        isPublic: initialValue?.isPublic,
        isActive: initialValue?.isActive,
      });
    }
  }, [initialValue]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        name="UserForm"
        labelWrap
        onFinish={onSubmit}
        initialValues={{
          countryCode: 'PER',
          isPublic: false,
          isActive: true,
          position: 1,
        }}
        {...formLayout}
      >
        <Form.Item<IFrequentQuestionCreateSchema> hidden name={['id']}>
          <Input />
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          label="Pregunta"
          name={['question']}
          rules={[{ required: true, message: 'Obligatorio', max: 500 }]}
        >
          <Input maxLength={500} />
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          label="Descripción"
          name={['answer']}
          hidden
          rules={[{ required: true, message: 'Obligatorio', max: 1500 }]}
        >
          <Input />
        </Form.Item>

        <RichEditor
          value={answerValue}
          modules={richTextModules}
          formats={['bold', 'italic', 'underline', 'link', 'list']}
          placeholder="Ingresar respuesta"
          style={{
            position: 'relative',
            zIndex: 999,
            border: answerError ? '1px solid red' : '',
          }}
          onChange={onChangeRichEditor}
        />
        {answerError && (
          <Typography.Text type="danger" style={{ fontSize: '11px' }}>
            Máximo 1500 caracteres
          </Typography.Text>
        )}

        <br />

        <Form.Item<IFrequentQuestionCreateSchema>
          label="Módulo"
          name={['module']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          label="Roles"
          name={['roles']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            allowClear
            mode="multiple"
            loading={loading}
            placeholder="Seleccione"
            options={[
              { label: 'SELECCIONAR TODOS', value: 'ALL' },
              ...(roles?.map((role) => ({
                label: role.name,
                value: role.name,
              })) || []),
            ]}
            onChange={(value) => {
              if (value.includes('ALL')) {
                form.setFieldsValue({
                  roles: roles?.map((role) => role.name) || [],
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          label="Posición"
          name={['position']}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item {...formItemLayout}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12, display: 'block' }}
          >
            Orden de aparición en la página. Los números menores se mostrará
            primero.
          </Typography.Text>
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          label="País"
          name={['countryCode']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción" optionFilterProp="label">
            <Select.Option value="PER" label="Perú">
              Perú
            </Select.Option>
            <Select.Option value="BOL" label="Perú">
              Bolivia
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          name={['isPublic']}
          label="Público"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item {...formItemLayout}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12, display: 'block' }}
          >
            Al marcar como público, la pregunta será visible para los usuarios
            que no inician sesión.
          </Typography.Text>
        </Form.Item>

        <Form.Item<IFrequentQuestionCreateSchema>
          name={['isActive']}
          label="Activar"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item {...formItemLayout}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12, display: 'block' }}
          >
            Los FAQ inactivos no serán visibles para nadie.
          </Typography.Text>
        </Form.Item>

        <Form.Item {...formItemLayout}>
          <Button type="primary" htmlType="submit" block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
