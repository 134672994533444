import React from 'react'
import { Badge, Progress, Table, TableColumnsType } from 'antd'
import { blue } from '@ant-design/colors'
import { IOrderDeliveryGroupsLocationResponse } from '../../../../../../services/order-motorized/order-motorized.interface'
import OrderDeliveryGroup3 from './OrderDeliveryGroup3'
import { IOrderDispatchedGroupResponse } from '../../../../../../services/order-dispatched/order-dispatched.interface'

const OrderDeliveryGroup2 = (record: IOrderDispatchedGroupResponse) => {
  const columnsLevel2: TableColumnsType<IOrderDeliveryGroupsLocationResponse> = [
    {
      title: 'Ciudad', dataIndex: 'province', width: 200,
    },
    {
      title: 'Pedidos', dataIndex: 'totalOrder', width: 100, align: 'right',
      render: (_, order) => <>
        <Badge count={order.totalOrder} style={{ backgroundColor: '#888' }} />
      </>,
      sorter: (a, b) => a.totalOrder - b.totalOrder,
    },
    {
      title: 'Total', dataIndex: 'totalAmount', width: 250, align: 'left',
      render: (_, order) => <>
        {order.currency?.symbol} {order.totalAmount}
        <Progress
          style={{ marginLeft: '.5rem', width: '50px' }}
          percent={order.ratioAmount}
          size="small"
          showInfo={false}
          strokeColor={blue[5]}
        />
      </>,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Completado', dataIndex: 'ratioCompleted',
      render: (_, order) => <>
        <Progress
          style={{ width: '80%' }}
          strokeColor={{
            from: '#009688',
            to: '#87d068',
          }}
          percent={order.ratioCompleted}
          status="active"
        />
        <span> ({order.totalCompleted})</span>
      </>,
      sorter: (a, b) => a.ratioCompleted - b.ratioCompleted,
    },
    {
      title: 'Entregados', dataIndex: 'ratioDelivered',
      render: (_, order) => <>
        <Progress
          style={{ width: '80%' }}
          strokeColor={{
            from: '#108ee9',
            to: '#87d068',
          }}
          percent={order.ratioDelivered}
          status="active"
        />
        <span> ({order.totalDelivered})</span>
      </>,
      sorter: (a, b) => a.ratioDelivered - b.ratioDelivered,
    },
    {
      title: 'Rechazado', dataIndex: 'ratioRefused',
      render: (_, order) => <>
        <Progress
          style={{ width: '80%' }}
          strokeColor={{
            from: '#e91e63',
            to: '#ff9800',
          }}
          percent={order.ratioRefused}
          status="active"
        />
        <span> ({order.totalRefused})</span>
      </>,
      sorter: (a, b) => a.ratioRefused - b.ratioRefused,
    },
  ]

  return <>
    <Table
      rowKey="province"
      style={{ width: '99%' }}
      columns={columnsLevel2}
      dataSource={record.orders}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => <OrderDeliveryGroup3 record={record} />,
        rowExpandable: record => !!record.orders?.length,
      }}
    />
  </>
}

export default OrderDeliveryGroup2;
