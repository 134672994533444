import React, { FC, useEffect, useState } from 'react';
import { ICatalogSchema } from '../../../../../services/catalog/catalog.schema';
import { Button, Form, Input, Select, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSuppliers } from '../../../../../services/catalog/catalog.service';
import { useCompanyList } from '../../../../../services/company-list/company-list.service';
import { useCatalogStore } from '../../../../../services/catalog/catalog.store';

const { Option } = Select;

interface ICatalogEditFormProps {
  onSubmit: (data: ICatalogSchema) => void;
}

const CatalogEditForm: FC<ICatalogEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICatalogSchema>();
  const { getSuppliers, suppliers, loadingSuppliers } = useSuppliers();
  const { getCompanies, companies, loading } = useCompanyList();
  const { catalogSelected } = useCatalogStore();
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [enableField, setEnableField] = useState(false);

  useEffect(() => {
    getSuppliers();
    getCompanies();

    if (catalogSelected) {
      form.setFieldsValue({
        id: catalogSelected.id,
        name: catalogSelected.name,
        description: catalogSelected.description,
        isPrivate: catalogSelected.isPrivate,
        managingSupplierId: catalogSelected.company?.id,
        companyIds: catalogSelected.catalogsCompany.map((it) => it.company.id),
      });

      setEnableField(true);
    } else {
      form.setFieldValue('isPrivate', true);
      form.setFieldValue('id', null);

      setEnableField(false);
    }
  }, []);

  return (
    <>
      <Form
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="catalogForm"
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name={['name']}
          label="Nombre del catálogo"
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name={['description']}
          rules={[{ required: true, message: '' }]}
        >
          <TextArea placeholder="" />
        </Form.Item>

        <Form.Item
          label="Proveedor gestor"
          name={['managingSupplierId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            placeholder="Seleccione una opción"
            loading={loadingSuppliers}
            disabled={enableField}
            optionFilterProp="label"
            showSearch
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {suppliers?.map((r) => (
              <Option key={r.id} value={r.id} label={r.name}>
                {r.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="ECOM asociados"
          name={['companyIds']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select
            mode="multiple"
            placeholder="Buscar tiendas"
            value={selectedCompanies}
            loading={loading}
            onChange={setSelectedCompanies}
            style={{ width: '100%' }}
            options={companies.map((item) => ({
              value: item.id,
              label: `(ID:${item.id}) ${item.name}`,
            }))}
            optionFilterProp="label"
            showSearch
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Acceso privado"
          name={['isPrivate']}
          valuePropName="checked"
        >
          <Switch checked={true} />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form>
    </>
  );
};

export default CatalogEditForm;
