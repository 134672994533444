import React, { useEffect, useState } from 'react';
import { ActionIntersectionObserver } from '../../../../../components';
import {
  useProductBestSellers,
  useProductsGlobal,
} from '../../../../../services/product-global/product-global.service';
import { Typography, Spin } from 'antd';
import {
  TBestSellersFiltersArgs,
  TProductCatalogItemResponse,
  TProductGlobalFiltersArgs,
} from '../../../../../services/product-global/product-global.interface';
import { ProductGrid } from '../../components/ProductGrid/ProductGrid';
import { ProductDetailModal } from '../../components/ProductDetailModal/ProductDetailModal';
import { GeneralCatalogFilters } from '../GeneralCatalogFilters/GeneralCatalogFilters';

const { Title } = Typography;

export const GeneralCatalogContainer = () => {
  const { getProductsBestSellers, bestSellers, loadingBest } =
    useProductBestSellers();
  const { getProductsGlobal, products, loading, currentPage, totalPages } =
    useProductsGlobal();

  const [product, setProduct] = useState<TProductCatalogItemResponse>();
  const [catalogFilters, setCatalogFilters] =
    useState<TProductGlobalFiltersArgs>();
  const [bestSellersFilters, setBestSellersFilters] =
    useState<TBestSellersFiltersArgs>();

  useEffect(() => {
    if (catalogFilters) {
      getProductsGlobal(catalogFilters);
    }
  }, [catalogFilters]);

  useEffect(() => {
    if (bestSellersFilters) {
      getProductsBestSellers(bestSellersFilters);
    }
  }, [bestSellersFilters]);

  const handleCatalogPagination = () => {
    if (catalogFilters && !loading && currentPage < totalPages) {
      getProductsGlobal({
        ...catalogFilters,
        page: currentPage + 1,
      });
    }
  };

  return (
    <>
      <GeneralCatalogFilters
        onProductGlobalFilterChange={setCatalogFilters}
        onBestSellersFilterChange={setBestSellersFilters}
      />

      <div style={{ background: '#f9f9f9' }}>
        {!!bestSellers?.length && (
          <>
            <Title
              level={5}
              style={{ marginLeft: 24, color: '#006494', marginTop: 16 }}
            >
              Productos ganadores del mes ⭐
            </Title>
            <Spin spinning={loadingBest}>
              <ProductGrid
                isLoading={loadingBest}
                products={bestSellers}
                onSelected={setProduct}
              />
            </Spin>
          </>
        )}

        <Title level={4} style={{ marginLeft: 24 }}>
          Productos
        </Title>

        <ProductGrid
          isLoading={loading}
          products={products}
          onSelected={setProduct}
        />

        <ActionIntersectionObserver
          loading={loading}
          onVisible={handleCatalogPagination}
          onAction={handleCatalogPagination}
        />

        {product && (
          <ProductDetailModal
            open
            product={product}
            onAccept={() => setProduct(undefined)}
            onCancel={() => setProduct(undefined)}
          />
        )}
      </div>
    </>
  );
};
