import { IAuthResponse } from '../auth/auth.interface'
import { IOrderCreateRequest, IOrderUpdateRequest } from './order-update.interface'
import { IOrderUpdateSchema } from './order-update.schema'
import { ICompanyItemResponse } from '../company-list/company-list.interface'

const mapToOrderUpdateRequest = (
  authUser: IAuthResponse,
  order: IOrderUpdateSchema,
): IOrderUpdateRequest => {

  const total = order.orderDetails.length ? order.orderDetails?.filter(t => t.action !== 'delete')
    .map(p => p.subtotal)
    .reduce((a, b) => a + b)
    : 0

  const productDetail = order.orderDetails
    .map(p => `${p.quantity} ${p.productName}`)
    .join(', ')

  const adds = order.orderDetails.filter(e => e.action === 'add')
  const updates = order.orderDetails.filter(e => e.action === 'update')

  let warehouseName = ''
  let warehouseId = -1
  let warCompanyId = undefined
  
  if (adds.length > 0) {
    warehouseName = adds[0].warehouseName
    warehouseId = adds[0].warehouseId
    warCompanyId = adds[0].warCompanyId
  } else if (updates.length > 0) {
    warehouseName = updates[0].warehouseName
    warehouseId = updates[0].warehouseId
    warCompanyId = updates[0].warCompanyId
  }

  const isEcom = authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER' || authUser.user.role.name === 'ADMIN'

  return {
    total: parseFloat(`${total}`),
    userId: order.user.id,
    comment: order.comment || '',
    updatedBy: authUser.user.id,
    flagMaster: authUser.user.role.name === 'MASTER',
    countryCode: authUser.user.company.countryCode,
    flagEcom: isEcom,
    note: order.note || '',
    channel: order.channel || '',
    guideSecundary: order.guideSecundary,
    status: order.status as string,
    trackingStatus: order.trackingStatus as string,
    transportId: order.transportId,
    paymentType: order.paymentType,
    orderRelated: authUser.user.role.name === 'MASTER' ? order.orderRelated : undefined,
    callStatus: order.callStatus as string,
    isOrderAgency: order.shipping.shippingByAgency,
    warehouseName: warehouseName,
    managementType : order.managementType,
    payAgency: order.payAgency,
    voucherPayAgency: order.voucherPayAgency,
    flagProductRemnant: order.isProductRemnant,
    shippingCost: order.shippingCost ? parseFloat(String(order.shippingCost)) : 0,
    returnCost: order.returnCost ? parseFloat(String(order.returnCost)) : 0,
    additionalDeliveryCost: order.additionalDeliveryCost ? parseFloat(String(order.additionalDeliveryCost)) : 0,
    customer: {
      companyId: order.customer.companyId,
      name: order.customer.name,
      lastName: order.customer.lastName || '',
      phone: order.customer.phone,
    },
    warehouseId: warehouseId,
    warehouse: {
      id: warehouseId,
      name: warehouseName,
      companyId: warCompanyId,
    },
    productDetail,
    orderDetailsUpdate: order.orderDetails?.length ? {
      delete: order.orderDetails
        .filter(e => e.action === 'delete')
        .map(e => ({
          id: e.id as number,
          quantity: e.quantity,
          skuId: e.skuId,
          companyId: e.companyId,
          warehouseId: e.warehouseId
        })),
      add: order.orderDetails
        .filter(e => e.action === 'add')
        .map(e => ({
          price: parseFloat(String(e.price)),
          quantity: e.quantity,
          subtotal: parseFloat(`${e.subtotal}`),
          skuId: e.skuId,
          companyId: e.companyId,
          warehouseId: e.warehouseId,
          dropPrice: e.dropPrice,
          quantityRemnant: parseFloat(`${e.quantityRemnant || 0}`)
        })),
      update: order.orderDetails
        .filter(e => e.action === 'update')
        .map(e => ({
          id: e.id as number,
          price: parseFloat(String(e.price)),
          quantity: e.quantity,
          subtotal: parseFloat(`${e.subtotal}`),
          skuId: e.skuId,
          companyId: e.companyId,
          warehouseId: e.warehouseId,
          dropPrice: e.dropPrice,
          quantityRemnant: parseFloat(`${e.quantityRemnant || 0}`)
        })),
    } : null,
    shipping: {
      id: order.shipping.id,
      address1: order.shipping.address1,
      address2: order.shipping.address2 || '',
      reference: order.shipping.reference || '',
      lat: order.shipping.gps?.split(',')[0] || '',
      lng: order.shipping.gps?.split(',')[1] || '',
      countryName: authUser.user.company.setting.country.name,
      countryCode: authUser.user.company.setting.country.code,
      departmentName: order.shipping.department || null,
      departmentCode: `${order.shipping.departmentId}` || null,
      provinceName: order.shipping.province || null,
      provinceCode: `${order.shipping.provinceId}` || null,
      districtName: order.shipping.district || null,
      districtCode: `${order.shipping.districtId}` || null,
      scheduleDate: order.shipping.scheduleDate || null,
      dispatchDate: order.shipping.dispatchDate || null,
      shippingByAgency: order.shipping.shippingByAgency,
      agencyName: order.shipping.agencyName || '',
      agencyAddress: order.shipping.agencyAddress || '',
      contactName: order.shipping.contactName || '',
      contactPhone: order.shipping.contactPhone || '',
      contactDocumenType: order.shipping.contactDocumenType || '',
      contactDocumentNumber: order.shipping.contactDocumentNumber || '',
      guideNumber: order.shipping.guideNumber || '',
      keyCode: order.shipping.keyCode || '',
      attachFile: order.shipping.attachFile || '',
      addressPickUp: order.shipping.addressPickUp || '',
      shippingCost: parseFloat(String(order.shipping.shippingCost)) || 0
    },
  }
}

const mapToOrderCreateRequest = (
  authUser: IAuthResponse,
  order: IOrderUpdateSchema,
  companyGlobal: ICompanyItemResponse | null,
): IOrderCreateRequest => {

  const total = order.orderDetails.filter(e => e.action !== 'delete').length 
    ? order.orderDetails.filter(e => e.action !== 'delete').map(p => p.subtotal)
      .reduce((a, b) => a + b) : 0

  const companyId = authUser.user.company.type === 'PRINCIPAL' ? (
    companyGlobal?.id || authUser.user.company.id) : authUser.user.company?.id

  const prefix = authUser.user.company.type === 'PRINCIPAL' ? (
    companyGlobal?.setting.companyPrefix || authUser.user.company.setting.companyPrefix) 
    : authUser.user.company.setting.companyPrefix

  let orderNumber = ''

  if (order.prodShopifyDetail && order.prodShopifyDetail.length > 0 && order.shipping.shippingByAgency === true) {
    orderNumber = order.orderNumberShopify || `${prefix}${`${new Date().getTime()}`.slice(-7)}`
  } else {
    orderNumber = `${prefix}${`${new Date().getTime()}`.slice(-7)}`
  }

  const sCost = () => {
    const department = order.shipping.departmentId || '0'

    if (department === '0') return 0
    if (authUser.user.company.countryCode === 'PER') {
      return parseFloat(String(order.shippingCost))
    } else if (authUser.user.company.countryCode === 'BOL') {
      return parseFloat(String(order.shippingCost))
    } else {
      return order.shippingCost ? parseFloat(String(order.shippingCost)) 
        : authUser.user.company.setting.amountDelivery
    }
  }

  const adds = order.orderDetails.filter(e => e.action === 'add')
  const updates = order.orderDetails.filter(e => e.action === 'update')

  let warehouseName = ''
  let warehouseId = -1
  
  if (adds.length > 0) {
    warehouseName = adds[0].warehouseName
    warehouseId = adds[0].warehouseId
  } else if (updates.length > 0) {
    warehouseName = updates[0].warehouseName
    warehouseId = updates[0].warehouseId
  }

  return {
    orderNumber: orderNumber,
    userId: authUser.user.id,
    total,
    note: order.note || '',
    channel: order.channel || '',
    status: order.status as string,
    callStatus: 'CONFIRMED',
    currency: authUser.user.company.setting.currency,
    isOrderAgency: order.shipping.shippingByAgency,
    trackingStatus: order.trackingStatus as string,
    paymentType: order.paymentType,
    shippingCost: sCost(),
    managementType: order.managementType,
    payAgency: order.payAgency,
    voucherPayAgency: order.voucherPayAgency,
    warehouseName: warehouseName,
    warehouseId: warehouseId || -1,
    createdAtShopify: order.createdAtShopify,
    transportId: order.transportId,
    orderRelated: authUser.user.role.name === 'MASTER' ? order.orderRelated : undefined,
    productShopifyDetail: order.prodShopifyDetail,
    customer: {
      companyId: companyId,
      name: order.customer.name,
      lastName: order.customer.lastName || '',
      phone: order.customer.phone,
    },
    orderDetails: order.orderDetails.filter(e => e.action !== 'delete').map((p) => ({
      price: parseFloat(String(p.price)),
      quantity: p.quantity,
      subtotal: p.subtotal,
      skuId: p.skuId,
      warehouseId: p.warehouseId || -1,
      companyId: p.companyId,
      dropPrice: p.dropPrice,
      storeCentralProductId: p.storeCentralProductId,
    })),
    preOrderHistory: {},
    shipping: {
      id: order.shipping.id,
      address1: order.shipping.address1 || '',
      address2: order.shipping.address2 || '',
      reference: order.shipping.reference || '',
      lat: order.shipping.gps?.split(',')[0] || '',
      lng: order.shipping.gps?.split(',')[1] || '',
      countryName: authUser.user.company.setting.country.name,
      countryCode: authUser.user.company.setting.country.code,
      departmentName: order.shipping.department || null,
      departmentCode: `${order.shipping.departmentId}` || null,
      provinceName: order.shipping.province || null,
      provinceCode: `${order.shipping.provinceId}` || null,
      districtName: order.shipping.district || null,
      districtCode: `${order.shipping.districtId}` || null,
      scheduleDate: order.shipping.scheduleDate,
      dispatchDate: order.shipping.dispatchDate,
      shippingByAgency: order.shipping.shippingByAgency,
      agencyName: order.shipping.agencyName || '',
      agencyAddress: order.shipping.agencyAddress || '',
      contactName: order.shipping.contactName || '',
      contactPhone: order.shipping.contactPhone || '',
      contactDocumenType: order.shipping.contactDocumenType || '',
      contactDocumentNumber: order.shipping.contactDocumentNumber || '',
      guideNumber: order.shipping.guideNumber || '',
      keyCode: order.shipping.keyCode || '',
      attachFile: order.shipping.attachFile || '',
      addressPickUp: order.shipping.addressPickUp || '',
      shippingCost: parseFloat(String(order.shipping.shippingCost)) || 0
    },
  }
}

export {
  mapToOrderUpdateRequest,
  mapToOrderCreateRequest,
}
