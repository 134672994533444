/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Table, TableColumnsType } from 'antd'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { ORDER_DISPATCH_STATUS_LABEL } from '../../../../../services/order-create/order.params'
import { useOrderDispatchTracking } from '../../../../../services/order-call/order-call.service'
import { IOrderTracking } from '../../../../../services/order-call/order-call.interface'

const OrderTrackingContainer = () => {
  const { orderSelected } = useOrderStore()
  const { loading, getOrdersTracking, response } = useOrderDispatchTracking()

  useEffect(() => {

    if (orderSelected) {
      getOrdersTracking(orderSelected.id)
    }

  }, [orderSelected])

  const columns: TableColumnsType<IOrderTracking> = [
    {
      title: 'Usuario',
      dataIndex: 'email',
      align: 'center',
      render: (_, d) => (<>{d.email}</>)
    },
    {
      title: 'Estado despacho',
      dataIndex: 'dispatchStatus',
      align: 'center',
      render: (_, d) => (<>{ORDER_DISPATCH_STATUS_LABEL[d.dispatchStatus].label}</>)
    },
    {
      title: 'Comentario',
      dataIndex: 'comment',
      render: (_, d) => (<>
        {d.comment || ''}</>)
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      render: (_, d) => (<>
        {d.createdAt}</>)
    },
    {
      title: 'Ubicación',
      dataIndex: 'latLng',
      render: (_, d) => (<>{d.latLng || ''}</>)
    },
  ]

  return <div style={{ overflow: 'auto' }}>
    {orderSelected ? (
      <>
        <Table
          rowKey="id"
          loading={loading}
          size='small'
          dataSource={response}
          columns={columns}
        />
      </>
    ) : null}
  </div>
}

export default OrderTrackingContainer
