import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../../../components';
import { CompanyChangeContainer } from '../../../../../containers';
import { useDrawer } from '../../../../../contexts/DrawerContext';
import { useMovementStore } from '../../../../../services/movement-create/movement.store';
import { useAuthStore } from '../../../../../services/auth/auth.service';
import { useCompanyStore } from '../../../../../services/company-create/company-create.service';
import { useMovementList } from '../../../../../services/movement-list/movement-list.service';
import { MovementsListTable } from '../../components';
import { useMovementEdit } from '../../../../../services/movement-create/movement.edit';
import { useWarehouseList } from '../../../../../services/warehouse-list/warehouse-list.service';
import { useProductParentFind } from '../../../../../services/product-parent/product-parent.service';

const { Option } = Select;

const MovementsMaintContainer = () => {
  const { authUser } = useAuthStore();
  const { companyGlobal } = useCompanyStore();
  const { openDrawer } = useDrawer();
  const { getMovements, movements, loading } = useMovementList();
  const { setMovementSelected, movementUpdated } = useMovementStore();
  const { getWarehouses, warehouses } = useWarehouseList();
  const { getProductParents, productParents } = useProductParentFind();
  const { isEditable } = useMovementEdit();
  const [defaultSelectedWarehouse, setDefaultSelectedWarehouse] =
    useState<string>('ALL');
  const [defaultSelectedProdParents, setDefaultSelectedProdParents] =
    useState<string>('ALL');
  const [warEnable, setWarEnable] = useState<boolean>(false);
  const [showAddProd, setShowAddProd] = useState(true);

  const handleOpenDrawer = () => {
    setMovementSelected(null);
    openDrawer('MOVEMENT_EDIT', 'Agregar movimiento');
  };

  useEffect(() => {
    if (movementUpdated) {
      setDefaultSelectedWarehouse('ALL');
      setDefaultSelectedProdParents('ALL');
      getMovements(
        companyGlobal ? `${companyGlobal.id}` : '',
        defaultSelectedWarehouse,
        defaultSelectedProdParents,
      );
    }
  }, [movementUpdated]);

  useEffect(() => {
    setDefaultSelectedProdParents('ALL');

    if (companyGlobal) {

      if (companyGlobal.id === 0) {
        setShowAddProd(true)
      } else {
        setShowAddProd(false)
      }
      
    } else {

      if (authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'NORMAL') {
        setShowAddProd(false)
      } else {
        setShowAddProd(true)
      }
      
    }

    if (authUser.user.role.name === 'STORE') {
      setDefaultSelectedWarehouse(`${authUser.user.warehouse?.id}`);
      setWarEnable(true);
    } else {
      setDefaultSelectedWarehouse('ALL');
      getWarehouses('-1');
    }

    if (authUser.user.company.countryCode === 'PER') {
      if (
        companyGlobal &&
        (authUser.user.role.name === 'ADMIN' ||
          authUser.user.role.name === 'MASTER' ||
          authUser.user.role.name === 'SUPER_MASTER' ||
          (authUser.user.role.name === 'STORE' && 
            authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'PRINCIPAL')
        )
      ) {
        getProductParents(`${companyGlobal.id}`, `${companyGlobal?.parentId}`);
        getMovements(
          `${companyGlobal.id}`,
          authUser.user.role.name === 'STORE'
            ? `${authUser.user.warehouse?.id}`
            : defaultSelectedWarehouse,
          defaultSelectedProdParents,
        );
      } else if (
        authUser.user.role.name === 'ADMIN_STORE' ||
        (authUser.user.role.name === 'STORE' && 
        authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'NORMAL')
      ) {
        getProductParents(
          `${authUser.user.company.id}`,
          `${authUser.user.company.parentId}`,
        );
        getMovements(
          `${authUser.user.company.id}`,
          authUser.user.role.name === 'STORE'
            ? `${authUser.user.warehouse?.id}`
            : defaultSelectedWarehouse,
          defaultSelectedProdParents,
        );
      }
    } else if (authUser.user.company.countryCode === 'BOL') {
      getProductParents(
        `${authUser.user.company.id}`,
        `${authUser.user.company.parentId}`,
      );
      getMovements(
        `${authUser.user.company.id}`,
        defaultSelectedWarehouse,
        defaultSelectedProdParents,
      );
    }
  }, [companyGlobal]);

  const handleChangeWarehouse = (warehouseId: string) => {
    getMovements(
      companyGlobal ? `${companyGlobal.id}` : '',
      warehouseId,
      defaultSelectedProdParents,
    );
    setDefaultSelectedWarehouse(warehouseId);
  };

  const handleChangeParents = (skuId: string) => {
    getMovements(
      companyGlobal ? `${companyGlobal.id}` : '',
      defaultSelectedWarehouse,
      skuId,
    );
    setDefaultSelectedProdParents(skuId);
  };

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>

          {(authUser.user.warehouse && authUser.user.warehouse.type && authUser.user.warehouse?.type === 'PRINCIPAL'
            && authUser.user.role.name === 'STORE'
          ) 
            && (
              <CompanyChangeContainer isAll={true} />
            )
          }

          {authUser.user.role?.permissions?.user_company_change &&
          authUser.user.company.countryCode === 'PER' &&
          authUser.user.role.name !== 'STORE' ? (
            <CompanyChangeContainer isAll={true} />
          ) : null}

          { showAddProd === true ? <>
          </> : <>
            <Button type="primary" onClick={handleOpenDrawer} hidden={isEditable}>
              Agregar Movimiento
            </Button>
          </>}

          { authUser.user.role.name === 'STORE' ? (
            <></>
          ) : (
            <>
              <span>Almacén</span>
              <Select
                placeholder="Almacenes"
                optionFilterProp="label"
                style={{ minWidth: '150px' }}
                onChange={handleChangeWarehouse}
                value={defaultSelectedWarehouse}
                disabled={warEnable}
                showSearch
                filterOption={(input, option) =>
                  (option?.label as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                <Option key="ALL" value="ALL" label={'TODOS'}>
                  TODOS
                </Option>
                {warehouses.map((key) => (
                  <Option key={key.id} value={key.id} label={key.name}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </>
          )}

          <span>Producto</span>
          <Select
            placeholder="Productos"
            optionFilterProp="label"
            style={{ minWidth: '150px' }}
            onChange={handleChangeParents}
            value={defaultSelectedProdParents}
            showSearch
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key="ALL" value="ALL" label={'TODOS'}>
              TODOS
            </Option>
            {productParents.map((key) => (
              <Option key={key.id} value={key.id} label={key.name}>
                {key.name}
              </Option>
            ))}
          </Select>
        </div>
      </PageHeader>

      <MovementsListTable
        movements={movements.filter(
          (m) => m.operation != 'PRE_SALES' && m.operation != 'SALES',
        )}
        loading={loading}
      />
    </>
  );
};

export default MovementsMaintContainer;
