import React, { FC, ReactNode } from 'react';
import { Header } from 'antd/lib/layout/layout';
import './Navbar.css';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routes.param';
import { Space } from 'antd';
import {
  InstagramOutlined,
  WhatsAppOutlined,
  TikTokOutlined,
} from '@ant-design/icons';

interface INavbarProps {
  children: ReactNode;
}

export const Navbar: FC<INavbarProps> = ({ children }) => {
  const location = useLocation();
  const locationSplit = location.pathname.split('/').filter((e) => e);

  const path = locationSplit[0];
  const subPath = locationSplit[1];

  const getPageTitle = (): string => {
    for (const module in ROUTES) {
      if (Object.prototype.hasOwnProperty.call(ROUTES, module)) {
        const element = ROUTES[module];

        if (`/${path}` === element.PATH && element.TITLE) {
          // If there are no children
          if (!element.CHILDREN) {
            return element.TITLE as string;
          }

          // Search in children
          for (const item in element.CHILDREN) {
            const child = element.CHILDREN[item];

            if (subPath === child.PATH) {
              // setTitle(child.TITLE as string);
              return child.TITLE as string;
            }
          }
        }
      }
    }

    return '';
  };

  return (
    <Header className="Navbar__header">
      <div className="Navbar__logo_menu">
        <span>{children}</span>
        <span style={{ color: 'white', fontSize: '15.5px', fontWeight: 500 }}>
          Aliclik / {getPageTitle()}
        </span>
      </div>

      <div>
        <Space size="middle">
          <a
            href="https://www.tiktok.com/@aliclikperu"
            target="_blank"
            rel="noopener noreferrer"
            className="tiktok"
          >
            <TikTokOutlined />
          </a>

          <a
            href="https://www.instagram.com/aliclikapp"
            target="_blank"
            rel="noopener noreferrer"
            className="instagram"
          >
            <InstagramOutlined />
          </a>

          <a
            href="https://whatsapp.com/channel/0029Van2G5vIXnltIwdPqN28"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp"
          >
            <WhatsAppOutlined />
          </a>
        </Space>
      </div>
    </Header>
  );
};
