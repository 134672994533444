import React from 'react';
import PrivateRoute from './PrivateRoute';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IRoutePath, ROUTES } from './routes.param';
import { LayoutApp } from '../modules/layout';

export const RouterConfig = () => {
  const publicRoutes = Object.values(ROUTES).filter((r) => r.PUBLIC);
  const privateRoutes = Object.values(ROUTES).filter((r) => !r.PUBLIC);

  const renderRoute = (route: IRoutePath, key: number) => {
    const children = route.CHILDREN ? Object.values(route.CHILDREN) : null;

    return (
      <Route
        key={`${key}-${route.PATH}`}
        path={route.PATH}
        element={route.ELEMENT}
      >
        {children?.map((childRoute: IRoutePath, index: number) => (
          <Route
            key={`${key}-${index}-${childRoute.PATH}`}
            path={childRoute.PATH}
            element={childRoute.ELEMENT}
          />
        ))}
      </Route>
    );
  };

  return (
    <Routes>
      <Route path="/">
        <Route
          path="/"
          element={<Navigate to={ROUTES.SIGN_IN.PATH} replace />}
        />

        {/* Dynamic routes */}
        {publicRoutes.map(renderRoute)}

        <Route element={<PrivateRoute Component={LayoutApp} />}>
          {privateRoutes.map(renderRoute)}
        </Route>
        {/* End dynamic routes */}
      </Route>

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
